import React from "react";
import Slider from "react-slick";

// Importing logos
import indeed from "../resources/logos/indeed.png";
import linkedin from "../resources/logos/linkedin.png";
import wttj from "../resources/logos/wttj.png";
import glassdoor from "../resources/logos/glassdoor.png";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class LogosCaroussel extends React.Component {
  // Define your logos array
  logos = [
    indeed,
    linkedin,
    wttj,
    glassdoor,
    indeed,
    linkedin,
    wttj,
    glassdoor,
  ];

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 8000,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      cssEase: "linear",
      autoplaySpeed: 0,
      pauseOnHover: false,
      arrows: false, // Added to remove left and right buttons
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          {this.logos.map((logo, index) => (
            <div key={index}>
              <img src={logo} alt="logo" style={{ width: "60%" }} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default LogosCaroussel;
