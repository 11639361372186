import React, { Component } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { colors } from "../colors";
import CustomButton from "./Button";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import {
  FileEarmarkArrowUpFill,
  Clipboard2CheckFill,
  FileEarmarkPlus,
  TrashFill,
  ChevronLeft,
} from "react-bootstrap-icons";
import { Spring, animated, Transition } from "@react-spring/web";
import {
  deleteResume,
  downloadResume,
  getCoverLettersByResume,
  getResumesByUser,
  updateResume,
  uploadResume,
} from "../api";
import AutoSizer from "react-virtualized-auto-sizer";
import { fontSizes } from "../fontSizes";
import TextInput from "./TextInput";
import { FileEarmarkText } from "react-bootstrap-icons";
import { withTranslationHOC } from "../withTranslation";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const templates = [
  {
    id: 1,
    content: (
      padding,
      coverLetter,
      entityName,
      recruiterName,
      entityAddress,
      candidateFirstName,
      candidateLastName,
      candidateAddress,
      candidatePhone,
      candidateEmail
    ) => (
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          fontSize: "9px",
          padding: padding,
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          color: "#000",
          border: "1px solid #ccc",
          margin: "auto",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {(candidateFirstName !== "" || candidateLastName !== "") && (
            <p style={{ margin: 0, padding: 0 }}>
              {candidateFirstName} {candidateLastName}
            </p>
          )}
          {candidateAddress !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidateAddress}</p>
          )}
          {candidatePhone !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidatePhone}</p>
          )}
          {candidateEmail !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidateEmail}</p>
          )}
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {entityName !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{entityName}</p>
          )}
          {recruiterName !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{recruiterName}</p>
          )}
          {entityAddress !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{entityAddress}</p>
          )}
        </div>

        <div style={{ marginBottom: "20px", textAlign: "justify" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: coverLetter?.letter?.replace(/\n/g, "<br />"),
            }}
          ></div>
        </div>
        <div style={{ marginTop: "20px" }}>
          {(candidateFirstName !== "" || candidateLastName !== "") && (
            <p>
              {candidateFirstName} {candidateLastName}
            </p>
          )}
        </div>
      </div>
    ),
  },
];

class ResumeVizComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: null,
      loadingFile: false,

      pdfFiles: [],

      languages: [
        { id: 1, name: "Français", code: "FRENCH" },
        { id: 2, name: "Anglais", code: "ENGLISH" },
        { id: 3, name: "Espagnol", code: "SPANISH" },
        { id: 4, name: "Allemand", code: "GERMAN" },
        { id: 5, name: "Italien", code: "ITALIAN" },
        { id: 6, name: "Portugais", code: "PORTUGUESE" },
        { id: 7, name: "Néerlandais", code: "DUTCH" },
        { id: 8, name: "Polonais", code: "POLISH" },
        { id: 9, name: "Russe", code: "RUSSIAN" },
        { id: 10, name: "Chinois", code: "CHINESE (ZH-CN)" },
        { id: 11, name: "Arabe", code: "ARABIC" },
      ],
    };
  }

  verifyInfoComplete = () => {
    return this.state.position && this.state.tips_language;
  };

  getResumes = async () => {
    try {
      const response = await getResumesByUser();

      for (const resume of response) {
        const blob = await downloadResume(resume.id);
        const url = URL.createObjectURL(blob);
        resume.url = url;
      }

      this.setState({ pdfFiles: response });
    } catch (error) {
      console.error("Error during fetching CVs:", error);
      throw error;
    }
  };
  uploadResume = async () => {
    // Open file dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = async (e) => {
      if (e.target.files.length > 0) {
        this.setState({ loadingFile: true });
        const file = e.target.files[0];
        try {
          const response = await uploadResume(file);

          const blob = await downloadResume(response.id);
          const url = URL.createObjectURL(blob);
          response.url = url;
          this.setState((state) => ({
            pdfFiles: [...state.pdfFiles, response],
          }));

          this.selectResume(response);

          console.log(response);
        } catch (error) {
          console.error("Error uploading resume:", error);
        }
      }
      this.setState({ loadingFile: false });
    };
    input.onclick = () => {
      document.body.onfocus = () => {
        setTimeout(() => {
          if (input.value.length === 0) {
            this.setState({ loadingFile: false });
          }
          document.body.onfocus = null;
        }, 500);
      };
    };
    input.click();
  };

  launchResumeAnalysis = async () => {
    try {
      const data = {
        position: this.state.position,
        tips_language: this.state.tips_language.code,
      };

      const resumeUpdated = await updateResume(this.state.resume.id, data);
      this.props.navigate(`/cooking`, {
        state: {
          resumeId: resumeUpdated.id,
          from: "cooker",
          action: "analyse",
        },
      });
    } catch (error) {
      console.error("Error during resume update:", error);
    }
  };

  componentDidMount() {
    this.getResumes();
  }

  deleteResume = async (resume) => {
    try {
      await deleteResume(resume.id);
      this.getResumes();
    } catch (error) {
      console.error("Error during deleting resume:", error);
    }
  };

  selectResume = async (resume) => {
    try {
      this.setState({ resume: resume, coverLetters: [] });
      getCoverLettersByResume(resume.id).then((coverLetters) => {
        this.setState({ coverLetters: coverLetters });
      });
    } catch (error) {
      console.error("Error during fetching cover letters:", error);
    }
  };

  renderTransitionSection = (delay, title, mark, description, example) => {
    return (
      <Transition
        items={this.state.resume?.status_generation_tips === "finished"}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
        config={{ duration: 1000 }}
        delay={delay}
      >
        {(styles, item) =>
          item && (
            <animated.div
              className="mb-3"
              style={{
                ...styles,
                display: "flex",
                borderRadius: 10,
                border: `2px solid ${colors.lightBlue}`,
                padding: "10px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "Manchego",
                    color: colors.darkBlue,
                    fontSize: "1.5em",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {title}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    color: colors.white,
                    fontSize: "0.8em",
                    backgroundColor:
                      mark === "bad"
                        ? colors.red
                        : mark === "average"
                        ? colors.yellow
                        : mark === "good"
                        ? colors.green
                        : "grey",
                    borderRadius: 10,
                    height: 25,
                    padding: "5px",
                    margin: 0,
                  }}
                >
                  {mark}
                </p>
              </div>
              <p
                className="mb-2"
                style={{
                  fontFamily: "Montserrat",
                  color: colors.darkBlue,
                  fontSize: "0.8em",
                  padding: 0,
                  margin: 0,
                }}
              >
                {description}
              </p>
              {example && (
                <>
                  <p
                    style={{
                      fontFamily: "Manchego",
                      color: colors.darkBlue,
                      textAlign: "right",
                      width: "100%",
                      fontSize: "1em",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    Exemple
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: colors.lightBlue,
                      padding: "10px",
                      borderRadius: 10,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        color: colors.white,
                        fontSize: "0.8em",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {example}
                    </p>
                    <div className="ms-2">
                      <Clipboard2CheckFill
                        style={{
                          cursor: "pointer",
                        }}
                        color={colors.background}
                        onClick={() => {
                          navigator.clipboard.writeText(example);
                        }}
                      ></Clipboard2CheckFill>
                    </div>
                  </div>
                </>
              )}
            </animated.div>
          )
        }
      </Transition>
    );
  };

  renderPdf = () => {
    let pdfFiles = [];
    const sortedPdfs = [...this.state.pdfFiles].sort(
      (a, b) => a.created_at < b.created_at
    );
    sortedPdfs.map((pdf) => {
      pdfFiles.push(
        <Spring
          to={{
            boxShadow:
              this.state.pdfHover === pdf.id
                ? `0 0 10px ${colors.lightBlue}`
                : `0 0 0px ${colors.lightBlue}`,
          }}
        >
          {(styles) => (
            <animated.div
              onClick={() => {
                this.selectResume(pdf);
              }}
              onMouseEnter={() => {
                this.setState({ pdfHover: pdf.id });
              }}
              onMouseLeave={() => {
                this.setState({ pdfHover: null });
              }}
              style={{
                ...styles,
                cursor: "pointer",
                borderRadius: 10,
                overflow: "hidden",
                height: 200,
                width: 200 / 1.41,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Document
                noData={""}
                loading={
                  <div
                    style={{
                      flex: 1,
                      height: 200,
                      width: 200 / 1.41,
                      display: "flex",

                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: colors.lightBlue,
                    }}
                  >
                    <Spinner
                      variant="light"
                      animation="border"
                      role="status"
                      size="sm"
                    ></Spinner>
                  </div>
                }
                file={pdf.url}
              >
                <Page noData={""} height={200} pageNumber={1} />
              </Document>
            </animated.div>
          )}
        </Spring>
      );
    });

    pdfFiles.push(
      <div
        onClick={this.uploadResume}
        style={{
          cursor: "pointer",
          backgroundColor: colors.blue,
          borderRadius: 10,
          overflow: "hidden",
          height: 200,
          width: 200 / 1.41,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `0 0 10px ${colors.lightBlue}`,
        }}
      >
        {this.state.loadingFile ? (
          <Spinner variant="light"></Spinner>
        ) : (
          <FileEarmarkArrowUpFill size={50} color={colors.background} />
        )}
      </div>
    );
    return pdfFiles;
  };

  render() {
    const { t } = this.props;

    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Row>
          <Col
            md={12}
            className="mb-3 mt-1"
            style={{
              fontFamily: "Manchego",
              display: "flex",
              alignItems: "center",
              backgroundColor: colors.lightBlue,
              borderRadius: 10,
              // boxShadow: `0 0 10px ${colors.darkBlue}`,
            }}
          >
            <div
              className="mb-2 mt-2 px-1"
              style={{
                color: colors.darkBlue,
                fontSize: fontSizes.large,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FileEarmarkText
                className="me-2"
                size={fontSizes.large}
              ></FileEarmarkText>
              {t("resumeVizComponent.myLettersAndResumes")}
            </div>
          </Col>
        </Row>
        <Row style={{ flex: 1, gap: "20px" }}>
          <Col
            md={6}
            style={{
              display: "flex", // Utilisez flex pour un layout flexible
              flexWrap: "wrap", // Permet aux éléments de passer à la ligne suivante si nécessaire
              border: "2px solid " + colors.blue,
              borderRadius: 10,
              padding: "10px",
              alignContent: "flex-start",
              flexDirection: "column",

              position: "relative",
            }}
          >
            {this.state.resume ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  zIndex: 100,
                  right: 10,
                  top: 10,
                  alignItems: "center",
                  borderRadius: 30,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: 50,
                    height: 50,
                  }}
                >
                  <CustomButton
                    style={{
                      backgroundColor: colors.blue,
                    }}
                    onClick={() => {
                      this.setState({ resume: null });
                    }}
                  >
                    <div>
                      <ChevronLeft size={20} color={colors.white} />
                    </div>
                  </CustomButton>
                </div>
                <div
                  className="ms-2"
                  style={{
                    width: 50,
                    height: 50,
                  }}
                >
                  <CustomButton
                    onClick={async () => {
                      await this.deleteResume(this.state.resume);
                      this.setState({ resume: null });
                    }}
                  >
                    <TrashFill size={20} color={colors.white} />
                  </CustomButton>
                </div>
              </div>
            ) : null}
            <Transition
              items={this.state.resume}
              config={{ duration: 200 }}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
              onRest={() => {}}
              exitBeforeEnter={true}
            >
              {(styles, item) =>
                item ? (
                  <animated.div
                    style={{
                      ...styles,
                      display: "flex",
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      overflow: "auto",
                      scrollbarWidth: "none", // Hide scrollbar for all browsers
                      msOverflowStyle: "none", // IE and Edge
                      "::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar for Chrome, Safari and Opera
                      },
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        height: 400,
                        width: 400 / 1.41,
                      }}
                    >
                      <Document
                        noData={""}
                        loading={
                          <div
                            style={{
                              flex: 1,
                              height: 200,
                              width: 200 / 1.41,
                              display: "flex",

                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: colors.lightBlue,
                            }}
                          >
                            <Spinner
                              variant="light"
                              animation="border"
                              role="status"
                              size="sm"
                            ></Spinner>
                          </div>
                        }
                        file={this.state.resume?.url}
                      >
                        <Page noData={""} pageNumber={1} />
                      </Document>
                    </div>
                  </animated.div>
                ) : (
                  <animated.div
                    style={{
                      ...styles,
                      flex: 1,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      alignContent: "flex-start",
                    }}
                  >
                    {this.renderPdf()}
                  </animated.div>
                )
              }
            </Transition>
          </Col>
          <Col
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: 0,
              opacity: 1,
              maxWidth: "100%",
              minHeight: 500,
            }}
          >
            {this.state.resume && (
              <div
                style={{ display: "flex", flex: 2, flexDirection: "column" }}
              >
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 10,
                    backgroundColor: colors.lightBlue,
                    boxShadow: `0 0 10px ${colors.lightBlue}`,
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Manchego",
                        color: colors.darkBlue,
                        fontSize: "1.5em",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {t("resumeVizComponent.tips")}
                    </p>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <div
                        style={{
                          height: height,
                          width: width,
                          overflow: "auto",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!this.state.resume?.tips && (
                          <div>
                            <div
                              style={{
                                backgroundColor: colors.lightBlue,
                                borderRadius: 10,
                                padding: 10,
                              }}
                            >
                              <div
                                className="mb-2"
                                style={{
                                  fontFamily: "Montserrat",
                                  color: colors.darkBlue,
                                  fontSize: fontSizes.normal,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {t("resumeVizComponent.notAnalyzed")}
                              </div>
                              <TextInput
                                animColor={colors.blue}
                                onChange={(e) =>
                                  this.setState({ position: e.target.value })
                                }
                                value={this.state.position}
                                className="mb-1"
                                style={{
                                  backgroundColor: colors.background,
                                  borderWidth: 2,
                                  color: colors.darkBlue,
                                  width: "100%",
                                  fontFamily: "Montserrat",
                                }}
                                placeholder={t(
                                  "resumeVizComponent.desiredPosition"
                                )}
                              ></TextInput>

                              <div
                                className="mt-2"
                                style={{
                                  fontFamily: "Montserrat",
                                  color: colors.darkBlue,
                                  fontSize: fontSizes.normal,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {t("resumeVizComponent.desiredLanguage")}
                              </div>
                              <Spring>
                                {(styles) => (
                                  <animated.div
                                    className="mt-2"
                                    style={{
                                      ...styles,
                                      borderRadius: 10,
                                      backgroundColor: colors.lightBlue,
                                      boxShadow: `0 0 10px ${colors.lightBlue}`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "10px",
                                        width: "100%",
                                        borderRadius: 10,
                                        alignContent: "flex-start",
                                        padding: 0,
                                      }}
                                    >
                                      {this.state.languages.map((language) => (
                                        <Spring
                                          key={language.id}
                                          to={{
                                            outlineWidth:
                                              this.state.langageHover ===
                                              language.id
                                                ? 2
                                                : 0,
                                            backgroundColor:
                                              this.state.tips_language?.id ===
                                              language.id
                                                ? colors.darkBlue
                                                : colors.background,
                                          }}
                                        >
                                          {(styles) => (
                                            <animated.div
                                              onMouseEnter={() => {
                                                this.setState({
                                                  langageHover: language.id,
                                                });
                                              }}
                                              onMouseLeave={() => {
                                                this.setState({
                                                  langageHover: null,
                                                });
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  tips_language: language,
                                                });
                                              }}
                                              style={{
                                                ...styles,
                                                outlineColor: colors.blue,
                                                outlineStyle: "solid",
                                                cursor: "pointer",
                                                display: "flex",
                                                borderRadius: 10,
                                                height: 30,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "10px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontFamily: "Montserrat",
                                                  color:
                                                    this.state.tips_language
                                                      ?.id === language.id
                                                      ? colors.white
                                                      : colors.darkBlue,
                                                  fontSize: "1em",
                                                }}
                                              >
                                                {language.name}
                                              </span>
                                            </animated.div>
                                          )}
                                        </Spring>
                                      ))}
                                    </div>
                                  </animated.div>
                                )}
                              </Spring>
                            </div>
                            <CustomButton
                              disabled={!this.verifyInfoComplete()}
                              onClick={this.launchResumeAnalysis}
                              className="mt-2"
                            >
                              {t("resumeVizComponent.analyzeResume")}
                            </CustomButton>
                          </div>
                        )}
                        {this.state.resume?.tips?.overall &&
                          this.renderTransitionSection(
                            0,
                            t("resumeVizComponent.general"),
                            this.state.resume?.tips?.overall?.mark,
                            this.state.resume?.tips?.overall?.description,
                            null
                          )}
                        {this.state.resume?.tips?.objective_statement &&
                          this.renderTransitionSection(
                            200,
                            t("resumeVizComponent.summaryParagraph"),
                            this.state.resume?.tips?.objective_statement?.mark,
                            this.state.resume?.tips?.objective_statement
                              ?.description,
                            this.state.resume?.tips?.objective_statement
                              ?.example
                          )}
                        {this.state.resume?.tips?.work_experiences &&
                          this.renderTransitionSection(
                            400,
                            t("resumeVizComponent.workExperience"),
                            this.state.resume?.tips?.work_experiences?.mark,
                            this.state.resume?.tips?.work_experiences
                              ?.description,
                            null
                          )}
                        {this.state.resume?.tips?.[
                          "education/certifications"
                        ] &&
                          this.renderTransitionSection(
                            600,
                            t("resumeVizComponent.education"),
                            this.state.resume?.tips?.[
                              "education/certifications"
                            ]?.mark,
                            this.state.resume?.tips?.[
                              "education/certifications"
                            ]?.description,
                            null
                          )}
                        {this.state.resume?.tips?.skills &&
                          this.renderTransitionSection(
                            800,
                            t("resumeVizComponent.skills"),
                            this.state.resume?.tips?.skills?.mark,
                            this.state.resume?.tips?.skills?.description,
                            this.state.resume?.tips?.skills?.example
                          )}
                        {this.state.resume?.tips?.metrics &&
                          this.renderTransitionSection(
                            1000,
                            t("resumeVizComponent.quantitativeResults"),
                            this.state.resume?.tips?.metrics?.mark,
                            this.state.resume?.tips?.metrics?.description,
                            this.state.resume?.tips?.metrics?.example
                          )}
                        {this.state.resume?.tips?.wording &&
                          this.renderTransitionSection(
                            1200,
                            t("resumeVizComponent.wording"),
                            this.state.resume?.tips?.wording?.mark,
                            this.state.resume?.tips?.wording?.description,
                            this.state.resume?.tips?.wording?.example
                          )}
                      </div>
                    )}
                  </AutoSizer>
                </div>
              </div>
            )}
            {this.state.resume && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 10,
                    backgroundColor: colors.lightBlue,
                    boxShadow: `0 0 10px ${colors.lightBlue}`,
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Manchego",
                        color: colors.darkBlue,
                        fontSize: "1.5em",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {t("resumeVizComponent.letters")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", flex: 1 }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <div
                        style={{
                          display: "flex",

                          flexDirection: "row",
                          width: width,
                          height: height,
                          gap: 10,
                          overflowX: "scroll",
                          padding: "5px",
                        }}
                      >
                        <Spring
                          boxShadow={
                            this.state.addLetterHovered
                              ? `0 0 5px ${colors.red}`
                              : `0 0 10px ${colors.background}`
                          }
                        >
                          {(styles) => (
                            <animated.div
                              onClick={() => {
                                this.props.navigate(`/cooking`, {
                                  state: {
                                    resumeId: this.state.resume.id,
                                    from: "cooker",
                                    action: "create",
                                  },
                                });
                              }}
                              onMouseEnter={() => {
                                this.setState({
                                  addLetterHovered: true,
                                });
                              }}
                              onMouseLeave={() => {
                                this.setState({ addLetterHovered: false });
                              }}
                              className="p-2"
                              style={{
                                ...styles,
                                display: "flex",
                                cursor: "pointer",

                                height: height - 10,

                                minWidth: height / 1.41 - 10,
                                backgroundColor: colors.red,
                                borderRadius: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <FileEarmarkPlus
                                size={50}
                                color={colors.background}
                              ></FileEarmarkPlus>
                              <div
                                className="mt-2"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: fontSizes.small,
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  color: colors.background,
                                }}
                              >
                                {t("resumeVizComponent.createNewLetter")}
                              </div>
                            </animated.div>
                          )}
                        </Spring>

                        {this.state.coverLetters
                          ?.filter(
                            (letter) => letter.status_generation_letter !== null
                          )
                          .map((coverLetter) => (
                            <Spring
                              boxShadow={
                                this.state.letterHovered?.id === coverLetter.id
                                  ? `0 0 5px ${colors.background}`
                                  : `0 0 10px ${colors.background}`
                              }
                              opacity={
                                this.state.letterHovered?.id === coverLetter.id
                                  ? 1
                                  : 0
                              }
                            >
                              {(styles) => (
                                <animated.div
                                  onClick={() => {
                                    this.props.navigate(`/cooking`, {
                                      state: {
                                        coverLetterId: coverLetter.id,
                                        from: "cooker",
                                        action: "edit",
                                      },
                                    });
                                  }}
                                  onMouseEnter={() => {
                                    this.setState({
                                      letterHovered: coverLetter,
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    this.setState({ letterHovered: null });
                                  }}
                                  style={{
                                    boxShadow: styles.boxShadow,
                                    cursor: "pointer",

                                    height: height - 10,

                                    minWidth: height / 1.41 - 10,
                                    backgroundColor: colors.lightBlue,
                                    borderRadius: 10,
                                    overflow: "hidden",
                                    position: "relative",
                                  }}
                                >
                                  {this.state.letterHovered?.id ===
                                    coverLetter.id && (
                                    <animated.div
                                      style={{
                                        opacity: styles.opacity,
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 100,
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            coverLetter.status_generation_letter ===
                                            "finished"
                                              ? colors.green
                                              : colors.red,
                                          borderTopRightRadius: 10,
                                          borderTopLeftRadius: 10,
                                          padding: "5px",
                                          fontFamily: "Montserrat",
                                          fontSize: fontSizes.small,
                                          color: colors.white,
                                          textAlign: "center",
                                        }}
                                      >
                                        {coverLetter.status_generation_letter ===
                                        "finished"
                                          ? t("resumeVizComponent.generated")
                                          : t("resumeVizComponent.inProgress")}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: "5px",
                                            fontFamily: "Montserrat",
                                            fontSize: fontSizes.small,
                                            color: colors.white,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {coverLetter.position}
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px",
                                            fontFamily: "Montserrat",
                                            fontSize: fontSizes.small,
                                            color: colors.white,
                                            textAlign: "left",
                                          }}
                                        >
                                          {coverLetter.entity_name}
                                        </div>
                                      </div>
                                    </animated.div>
                                  )}
                                  <AutoSizer>
                                    {({ width, height }) => {
                                      const aspectRatio = 210 / 297;
                                      let adjustedWidth = width;
                                      let adjustedHeight = height;
                                      const containerAspectRatio =
                                        width / height;

                                      // Adjust width or height based on the container's aspect ratio
                                      if (containerAspectRatio > aspectRatio) {
                                        // Container is wider than A4 aspect ratio
                                        adjustedWidth = height * aspectRatio;
                                      } else {
                                        // Container is taller than A4 aspect ratio
                                        adjustedHeight = width / aspectRatio;
                                      }

                                      const padding = 0.05 * adjustedWidth;

                                      return (
                                        <div
                                          className="div-to-pdf"
                                          style={{
                                            scale: "1.05",
                                            width: adjustedWidth,
                                            height: adjustedHeight,
                                            //backgroundColor: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "hidden",
                                            fontSize: `${
                                              adjustedWidth * 0.015
                                            }px`, // 1% of the adjustedWidth
                                            position: "relative",
                                          }}
                                        >
                                          {templates[0].content(
                                            padding,
                                            coverLetter,
                                            coverLetter.entity_name,
                                            coverLetter.recruiter_name,
                                            coverLetter.entity_address,
                                            coverLetter.candidate_first_name,
                                            coverLetter.candidate_last_name,
                                            coverLetter.candidate_address,
                                            coverLetter.candidate_phone,
                                            coverLetter.candidate_email
                                          )}
                                        </div>
                                      );
                                    }}
                                  </AutoSizer>
                                </animated.div>
                              )}
                            </Spring>
                          ))}
                      </div>
                    )}
                  </AutoSizer>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslationHOC(ResumeVizComponent);
