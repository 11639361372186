import React, { Component } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { colors } from "../colors";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { Spring, animated, Transition } from "@react-spring/web";
import { withRouter } from "../withRouter";
import { withTranslationHOC } from "../withTranslation";
import ResumeVizComponent from "../components/ResumeVizComponent";
import { fontSizes } from "../fontSizes";
import { me } from "../api";

class CookerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }

  getUser = async () => {
    try {
      const user = await me();
      this.setState({ user });
    } catch (e) {
      console.log(e);
      this.props.navigate("/login");
    }
  };

  changeStep = (step) => {
    this.setState({ step });
  };

  changeCoverLetterId = (coverLetterId) => {
    this.setState({ coverLetterId });
  };

  componentWillUnmount() {
    clearInterval(this.checkPaymentInterval);
  }

  async componentDidMount() {
    await this.getUser();
  }

  render() {
    const { t } = this.props;

    if (this.state.user) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: colors.background,
          }}
        >
          <Navbar style={{ backgroundColor: colors.lightBlue }} expand="lg">
            <Container>
              <Navbar.Brand
                style={{ fontFamily: "Manchego", color: colors.darkBlue }}
                href="#home"
              >
                CoverCooker<span style={{ color: colors.background }}>.ai</span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link
                    href="/cooking"
                    className="ms-auto"
                    style={{ textAlign: "left" }}
                  >
                    <Spring
                      boxShadow={
                        this.state.linkHovered
                          ? `0 0 10px ${colors.red}`
                          : `0 0 0px ${colors.red}`
                      }
                    >
                      {(styles) => (
                        <animated.div
                          className="px-2 py-1"
                          onMouseEnter={() => {
                            this.setState({ linkHovered: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ linkHovered: false });
                          }}
                          style={{
                            ...styles,
                            borderRadius: 10,
                            color: colors.background,
                            fontFamily: "Manchego",
                            fontSize: fontSizes.normal,
                            fontWeight: "bold",
                            backgroundColor: colors.red,
                          }}
                        >
                          {t("cookerPage.generateNewLetter")}
                        </animated.div>
                      )}
                    </Spring>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Container
            className="py-3"
            style={{
              marginTop: 0,
              paddingTop: 0,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Transition
              items={this.state.step}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
              exitBeforeEnter={true}
            >
              {(styles, step) =>
                step && (
                  <animated.div style={{ ...styles, display: "flex", flex: 1 }}>
                    {(() => {
                      switch (step) {
                        case 1:
                          return (
                            <ResumeVizComponent
                              navigate={this.props.navigate}
                              changeCoverLetterId={this.changeCoverLetterId.bind(
                                this
                              )}
                              changeStep={this.changeStep.bind(this)}
                            />
                          );

                        default:
                          return null;
                      }
                    })()}
                  </animated.div>
                )
              }
            </Transition>
          </Container>
        </div>
      );
    }
  }
}

export default withRouter(withTranslationHOC(CookerPage));
