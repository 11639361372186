export const colors = {
  background: "#EEE9DA",
  lightBlue: "#BDCDD6",

  blue: "#93BFCF",
  darkBlue: "#6096B4",
  red: "#E87461",
  yellow: "#E8D461",
  green: "#61E874",
  white: "#ffffff",
};
