import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { colors } from "../colors";
import CustomButton from "./Button";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import TextInput from "./TextInput";
import { Spring, animated, Transition } from "@react-spring/web";
import AutoSizer from "react-virtualized-auto-sizer";
import { ThreeDots } from "react-loader-spinner";
import PayTheBillComponent from "./PayTheBillComponent";
import {
  createInterviewAdvice,
  generateCoverLetter,
  generateInterviewAdvice,
  getCoverLetterById,
  getInterviewAdviceByCoverLetterId,
  getInterviewAdviceById,
  me,
  updateCoverLetter,
  updateUser,
} from "../api";
import { withTranslationHOC } from "../withTranslation";

import ReactMarkdown from "react-markdown";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { FiletypePdf, X } from "react-bootstrap-icons";
import { fontSizes } from "../fontSizes";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const WIDTH_FORMAT_A4 = 210;
const HEIGHT_FORMAT_A4 = 297;

const templates = [
  {
    id: 1,
    content: (
      padding,
      coverLetter,
      entityName,
      recruiterName,
      entityAddress,
      candidateFirstName,
      candidateLastName,
      candidateAddress,
      candidatePhone,
      candidateEmail
    ) => (
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          padding: padding,
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          color: "#000",
          border: "1px solid #ccc",
          margin: "auto",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {(candidateFirstName !== "" || candidateLastName !== "") && (
            <p style={{ margin: 0, padding: 0 }}>
              {candidateFirstName} {candidateLastName}
            </p>
          )}
          {candidateAddress !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidateAddress}</p>
          )}
          {candidatePhone !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidatePhone}</p>
          )}
          {candidateEmail !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{candidateEmail}</p>
          )}
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {entityName !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{entityName}</p>
          )}
          {recruiterName !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{recruiterName}</p>
          )}
          {entityAddress !== "" && (
            <p style={{ margin: 0, padding: 0 }}>{entityAddress}</p>
          )}
        </div>

        <div style={{ marginBottom: "20px", textAlign: "justify" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: coverLetter?.letter?.replace(/\n/g, "<br />"),
            }}
          ></div>
        </div>
        <div style={{ marginTop: "20px" }}>
          {(candidateFirstName !== "" || candidateLastName !== "") && (
            <p>
              {candidateFirstName} {candidateLastName}
            </p>
          )}
        </div>
      </div>
    ),
  },
];

class CookCoverComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: "0%",
      coverLetter: null,
      resume: null,
      analysisLaunched: false,
      entityName: "",
      recruiterName: "",
      entityAddress: "",
      candidateFirstName: "",
      candidateLastName: "",
      candidateAddress: "",
      candidatePhone: "",
      candidateEmail: "",
      display: "cover",
    };
    this.adjustedWidth = null;
    this.adjustedHeight = null;
  }

  componentWillUnmount() {
    clearInterval(this.statusCoverLetterCheckInterval);

    // setTimeout(() => {
    //   this.setState({ analyseOk: true });
    // }, 00);
  }

  updateUser = async (key, event) => {
    try {
      console.log(key, event.target.value);
      const data = {
        [key]: event.target.value,
      };
      const updatedUser = await updateUser(this.state.user.id, data);
      this.setState({ user: updatedUser });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  updateCoverLetter = async (key, event) => {
    try {
      const data = {
        [key]: event.target.value,
      };

      await updateCoverLetter(this.state.coverLetter.id, data);
    } catch (error) {
      console.error("Error updating cover letter:", error);
    }
  };

  async launchCoverLetterAnalysis(coverLetterArg) {
    try {
      let coverLetter = coverLetterArg;
      if (!coverLetter.status_generation_letter) {
        try {
          coverLetter = await generateCoverLetter(coverLetter.id);
          this.props.getUser();
        } catch (error) {
          if (error.response && error.response.status === 402) {
            this.setState({ display: "payment" });
            return;
          } else {
            console.error("Error generating cover letter:", error);
          }
        }
      }

      if (coverLetter.status_generation_letter !== "finished") {
        this.statusCoverLetterCheckInterval = setInterval(async () => {
          try {
            const updatedCoverLetter = await getCoverLetterById(coverLetter.id);
            this.setState({ coverLetter: updatedCoverLetter });
            if (updatedCoverLetter.status_generation_letter === "finished") {
              this.props.getUser();
              clearInterval(this.statusCoverLetterCheckInterval);
            }
          } catch (error) {
            console.error("Error checking cover letter status:", error);
          }
        }, 100);
      } else {
        clearInterval(this.statusCoverLetterCheckInterval);
        this.setState({ coverLetter });
      }
    } catch (error) {}
  }

  async launchInterviewAdviceAnalysis(iAdvicesArg) {
    try {
      let iAdvices = iAdvicesArg;
      if (!iAdvices.status_generation_advice) {
        try {
          iAdvices = await generateInterviewAdvice(iAdvices.id);
          this.props.getUser();
        } catch (error) {
          if (error.response && error.response.status === 402) {
            // this.setState({ display: "payment" });
            return;
          } else {
            console.error("Error generating cover letter:", error);
          }
        }
      }

      if (iAdvices.status_generation_advice !== "finished") {
        this.statusIAdvicesCheckInterval = setInterval(async () => {
          try {
            const updatedIAdvices = await getInterviewAdviceById(iAdvices.id);
            this.setState({ iAdvices: updatedIAdvices });
            if (updatedIAdvices.status_generation_advice === "finished") {
              this.props.getUser();
              clearInterval(this.statusIAdvicesCheckInterval);
            }
          } catch (error) {
            console.error("Error checking cover letter status:", error);
          }
        }, 300);
      } else {
        clearInterval(this.statusIAdvicesCheckInterval);
        this.setState({ iAdvices });
      }
    } catch (error) {}
  }

  downloadPDF() {
    const { t } = this.props;
    const input = document.getElementsByClassName("div-lettre")[0];

    input.style.width = WIDTH_FORMAT_A4;
    input.style.height = HEIGHT_FORMAT_A4;

    html2canvas(input, {
      scale: 4,
    }).then((canvas) => {
      input.style.width = this.adjustedWidth;
      input.style.height = this.adjustedHeight;

      const imgData = canvas.toDataURL("image/jpeg");

      const pdf = new jsPDF({});

      pdf.addImage(imgData, "JPG", 0, 0, WIDTH_FORMAT_A4, HEIGHT_FORMAT_A4);
      pdf.save(t("cookCoverComponent.letterOfMotivation"));
    });
  }

  async componentDidMount() {
    try {
      const user = await me();

      this.setState({ candidateEmail: user.email, user });

      const coverLetter = await getCoverLetterById(this.props.coverLetterId);
      let iAdvices;
      try {
        iAdvices = await getInterviewAdviceByCoverLetterId(coverLetter.id);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          iAdvices = await createInterviewAdvice(coverLetter.id, {});
        }
      }

      this.setState({
        coverLetter,
        iAdvices,
        entityName: coverLetter.entity_name,
        candidateFirstName: user.first_name ? user.first_name : "",
        candidateLastName: user.last_name ? user.last_name : "",
        candidateEmail: user.email ? user.email : "",
        candidateAddress: user.address ? user.address : "",
        candidatePhone: user.phone ? user.phone : "",
        entityAddress: coverLetter.address ? coverLetter.address : "",
        recruiterName: coverLetter.recruiter_name
          ? coverLetter.recruiter_name
          : "",
      });

      if (coverLetter.status_generation_letter !== "finished") {
        this.launchCoverLetterAnalysis(coverLetter);
      }

      if (iAdvices.status_generation_advice !== "finished") {
        this.launchInterviewAdviceAnalysis(iAdvices);
      }
    } catch (error) {}
  }

  renderLetter() {
    return (
      <AutoSizer>
        {({ width, height }) => {
          const aspectRatio = WIDTH_FORMAT_A4 / HEIGHT_FORMAT_A4;
          let adjustedWidth = width;
          let adjustedHeight = height;
          const containerAspectRatio = width / height;
          console.log(adjustedWidth, adjustedHeight);
          // Adjust width or height based on the container's aspect ratio
          if (containerAspectRatio > aspectRatio) {
            // Container is wider than A4 aspect ratio
            adjustedWidth = height * aspectRatio;
          } else {
            // Container is taller than A4 aspect ratio
            adjustedHeight = width / aspectRatio;
          }

          const padding = 0.05 * adjustedWidth;

          this.adjustedWidth = adjustedWidth;
          this.adjustedHeight = adjustedHeight;

          console.log(adjustedWidth, adjustedHeight);
          console.log("font size", adjustedWidth * 0.015);

          return (
            <div
              className="div-lettre"
              style={{
                margin: "auto",
                width: this.adjustedWidth,
                height: this.adjustedHeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                fontSize: `${adjustedWidth * 0.015}px`,
                position: "relative",
              }}
            >
              {templates[0].content(
                padding,
                this.state.coverLetter,
                this.state.entityName,
                this.state.recruiterName,
                this.state.entityAddress,
                this.state.candidateFirstName,
                this.state.candidateLastName,
                this.state.candidateAddress,
                this.state.candidatePhone,
                this.state.candidateEmail
              )}
            </div>
          );
        }}
      </AutoSizer>
    );
  }

  renderMarkdown(markdownText) {
    const markdownComponents = {
      // Define custom rendering for heading elements
      h1: ({ node, ...props }) => (
        <h1
          style={{ fontFamily: "Manchego", color: colors.darkBlue }}
          {...props}
        />
      ),
      h2: ({ node, ...props }) => (
        <h2
          style={{ fontFamily: "Manchego", color: colors.darkBlue }}
          {...props}
        />
      ),
      h3: ({ node, ...props }) => (
        <h3
          style={{
            fontFamily: "Manchego",
            color: colors.darkBlue,
            fontSize: fontSizes.xlarge,
          }}
          {...props}
        />
      ),
      h4: ({ node, ...props }) => (
        <h4
          style={{
            fontFamily: "Manchego",
            color: colors.darkBlue,
            fontSize: fontSizes.large,
          }}
          {...props}
        />
      ),
      p: ({ node, ...props }) => (
        <p
          className="mt-2"
          style={{ fontFamily: "Montserrat", color: colors.darkBlue }}
          {...props}
        />
      ),
      li: ({ node, ...props }) => (
        <li
          style={{ fontFamily: "Montserrat", color: colors.darkBlue }}
          {...props}
        />
      ),
    };

    return (
      <ReactMarkdown components={markdownComponents}>
        {markdownText}
      </ReactMarkdown>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Row style={{ gap: "20px" }}>
          <Col md={6} xs={12}>
            <div
              className="mb-3"
              style={{
                display: "flex",
                height: 50,
                width: "100%",
                backgroundColor: colors.lightBlue,
                borderRadius: 10,
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Spring
                boxShadow={
                  this.state.displayCoverHovered
                    ? `0 0 10px ${colors.lightBlue}`
                    : `0 0 0px ${colors.lightBlue}`
                }
              >
                {(styles) => (
                  <animated.div
                    onClick={() => {
                      this.setState({ display: "cover" });
                    }}
                    onMouseEnter={() => {
                      this.setState({ displayCoverHovered: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ displayCoverHovered: false });
                    }}
                    style={{
                      ...styles,
                      cursor: "pointer",
                      display: "flex",
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manchego",
                      color: colors.background,
                    }}
                  >
                    <div style={{ zIndex: 2 }}>
                      {t("cookCoverComponent.coverLetter")}
                    </div>
                  </animated.div>
                )}
              </Spring>
              <Spring
                boxShadow={
                  this.state.displayAdvicesHovered
                    ? `0 0 10px ${colors.lightBlue}`
                    : `0 0 0px ${colors.lightBlue}`
                }
              >
                {(styles) => (
                  <animated.div
                    onClick={() => {
                      this.setState({ display: "advices" });
                    }}
                    onMouseEnter={() => {
                      this.setState({ displayAdvicesHovered: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ displayAdvicesHovered: false });
                    }}
                    style={{
                      ...styles,
                      cursor: "pointer",
                      display: "flex",
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manchego",
                      color: colors.background,
                    }}
                  >
                    <div style={{ zIndex: 2 }}>
                      {t("cookCoverComponent.interviewAdvice")}
                    </div>
                  </animated.div>
                )}
              </Spring>
              <Spring left={this.state.display === "advices" ? "50%" : "0%"}>
                {(styles) => (
                  <animated.div
                    style={{
                      ...styles,
                      zIndex: 0,
                      boxShadow: `0 0 10px ${colors.darkBlue}`,

                      position: "absolute",
                      backgroundColor: colors.darkBlue,
                      display: "flex",
                      width: "50%",
                      height: "100%",
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manchego",
                      color: colors.darkBlue,
                    }}
                  ></animated.div>
                )}
              </Spring>
            </div>
          </Col>
        </Row>
        <Transition
          items={this.state.display}
          from={
            this.state.display === "cover"
              ? { opacity: 0, transform: "translate3d(100%, 0, 0)" }
              : {
                  opacity: 0,
                  transform: "translate3d(-100%, 0, 0)",
                }
          }
          enter={{ opacity: 1, transform: "translate3d(0%, 0, 0)" }}
          leave={
            this.state.display === "cover"
              ? { opacity: 0, transform: "translate3d(-100%, 0, 0)" }
              : {
                  opacity: 0,
                  transform: "translate3d(100%, 0, 0)",
                }
          }
          exitBeforeEnter={true}
        >
          {(styles, step) =>
            step && (
              <animated.div style={{ ...styles, display: "flex", flex: 1 }}>
                {(() => {
                  switch (step) {
                    case "payment":
                      return (
                        <div
                          style={{
                            ...styles,

                            position: "absolute",
                            zIndex: 200,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              height: "100%",
                              backgroundColor: colors.background,
                              borderRadius: 10,
                            }}
                          >
                            <PayTheBillComponent
                              getUser={this.props.getUser}
                              step={3}
                              comesFromCooker={this.props.comesFromCooker}
                              coverLetterId={this.props.coverLetterId}
                            />
                          </div>
                        </div>
                      );

                    case "cover":
                      return (
                        <div style={{ ...styles, display: "flex", flex: 1 }}>
                          <Row
                            style={{
                              flex: 1,
                              gap: "20px",
                            }}
                          >
                            <Col
                              md={6}
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                              }}
                            >
                              {!this.props.comesFromCooker && (
                                <div
                                  //className="mb-3"
                                  style={{
                                    borderRadius: 10,
                                    backgroundColor: colors.lightBlue,
                                    boxShadow: `0 0 10px ${colors.lightBlue}`,
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="me-2"
                                      style={{
                                        display: "flex",
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: colors.darkBlue,
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          color: colors.background,
                                          fontFamily: "Manchego",
                                          fontWeight: "bold",
                                          margin: 0,
                                          padding: 0,
                                        }}
                                      >
                                        2
                                      </h4>
                                    </div>
                                    <p
                                      style={{
                                        fontFamily: "Manchego",
                                        color: colors.darkBlue,
                                        fontSize: "1.5em",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {t("cookCoverComponent.writingLetter")}
                                    </p>
                                  </div>

                                  <ThreeDots
                                    visible={
                                      this.state.coverLetter
                                        ?.status_generation_letter !==
                                      "finished"
                                    }
                                    height="50%"
                                    width={40}
                                    color={colors.background}
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                </div>
                              )}
                              {/*<Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                      {(styles) => (
                        <animated.div
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            Template
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                              width: "100%",
                              borderRadius: 10,
                              alignContent: "flex-start",
                              padding: 0,
                            }}
                          >
                            {templates.map((template) => (
                              <Spring
                                boxShadow={
                                  this.state.templateHovered?.id === template.id
                                    ? this.state.templateSelected
                                      ? `0 0 10px ${colors.darkBlue}`
                                      : `0 0 10px ${colors.background}`
                                    : this.state.templateSelected
                                    ? `0 0 0px ${colors.darkBlue}`
                                    : `0 0 0px ${colors.background}`
                                }
                              >
                                {(styles) => (
                                  <animated.div
                                    onMouseEnter={() => {
                                      this.setState({
                                        templateHovered: template,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({
                                        templateHovered: null,
                                      });
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        templateSelected: template,
                                      });
                                    }}
                                    key={template.id}
                                    style={{
                                      ...styles,
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: 70,
                                      height: 50,
                                      padding: "10px",
                                      borderRadius: 10,
                                      backgroundColor:
                                        this.state.templateSelected?.id ===
                                        template.id
                                          ? colors.darkBlue
                                          : colors.background,
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Manchego",
                                        color:
                                          this.state.templateSelected?.id ===
                                          template.id
                                            ? colors.background
                                            : colors.darkBlue,
                                        fontSize: fontSizes.large,
                                      }}
                                    >
                                      {template.id}
                                    </span>
                                  </animated.div>
                                )}
                              </Spring>
                            ))}
                          </div>
                        </animated.div>
                      )}
                      </Spring>*/}

                              <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                                {(styles) => (
                                  <animated.div
                                    className="mt-3"
                                    style={{
                                      ...styles,
                                      borderRadius: 10,
                                      backgroundColor: colors.lightBlue,
                                      boxShadow: `0 0 10px ${colors.lightBlue}`,
                                      padding: "10px",
                                    }}
                                  >
                                    <p
                                      className="mb-2"
                                      style={{
                                        fontFamily: "Manchego",
                                        color: colors.darkBlue,
                                        fontSize: "1.5em",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {t(
                                        "cookCoverComponent.personalInformation"
                                      )}
                                    </p>
                                    <Row className="g-2">
                                      <Col xs={12} md={6}>
                                        <TextInput
                                          onBlur={this.updateUser.bind(
                                            this,
                                            "first_name"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              candidateFirstName:
                                                e.target.value,
                                            });
                                          }}
                                          value={this.state.candidateFirstName}
                                          placeholder={t(
                                            "cookCoverComponent.firstName"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col xs={12} md={6}>
                                        <TextInput
                                          onBlur={this.updateUser.bind(
                                            this,
                                            "last_name"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              candidateLastName: e.target.value,
                                            });
                                          }}
                                          value={this.state.candidateLastName}
                                          placeholder={t(
                                            "cookCoverComponent.lastName"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col md={12}>
                                        <TextInput
                                          onBlur={this.updateUser.bind(
                                            this,
                                            "address"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              candidateAddress: e.target.value,
                                            });
                                          }}
                                          value={this.state.candidateAddress}
                                          placeholder={t(
                                            "cookCoverComponent.postalAddress"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col md={6}>
                                        <TextInput
                                          onBlur={this.updateUser.bind(
                                            this,
                                            "phone"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              candidatePhone: e.target.value,
                                            });
                                          }}
                                          value={this.state.candidatePhone}
                                          placeholder={t(
                                            "cookCoverComponent.phone"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col md={6}>
                                        <TextInput
                                          onChange={(e) => {
                                            this.setState({
                                              candidateEmail: e.target.value,
                                            });
                                          }}
                                          value={this.state.candidateEmail}
                                          placeholder={t(
                                            "cookCoverComponent.email"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                    </Row>
                                  </animated.div>
                                )}
                              </Spring>
                              <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                                {(styles) => (
                                  <animated.div
                                    className="mt-3"
                                    style={{
                                      ...styles,
                                      borderRadius: 10,
                                      backgroundColor: colors.lightBlue,
                                      boxShadow: `0 0 10px ${colors.lightBlue}`,
                                      padding: "10px",
                                    }}
                                  >
                                    <p
                                      className="mb-2"
                                      style={{
                                        fontFamily: "Manchego",
                                        color: colors.darkBlue,
                                        fontSize: "1.5em",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {t(
                                        "cookCoverComponent.organizationInformation"
                                      )}
                                    </p>
                                    <Row className="g-2">
                                      <Col xs={12} md={6}>
                                        <TextInput
                                          onChange={(e) => {
                                            this.setState({
                                              entityName: e.target.value,
                                            });
                                          }}
                                          disabled={true}
                                          onBlur={this.updateCoverLetter.bind(
                                            this,
                                            "entity_name"
                                          )}
                                          value={this.state.entityName}
                                          placeholder={t(
                                            "cookCoverComponent.organizationName"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col xs={12} md={6}>
                                        <TextInput
                                          onBlur={this.updateCoverLetter.bind(
                                            this,
                                            "recruiter_name"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              recruiterName: e.target.value,
                                            });
                                          }}
                                          value={this.state.recruiterName}
                                          placeholder={t(
                                            "cookCoverComponent.recruiterName"
                                          )}
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                      <Col xs={12} md={12}>
                                        <TextInput
                                          onBlur={this.updateCoverLetter.bind(
                                            this,
                                            "address"
                                          )}
                                          onChange={(e) => {
                                            this.setState({
                                              entityAddress: e.target.value,
                                            });
                                          }}
                                          value={this.state.entityAddress}
                                          placeholder="Adresse postale"
                                          animColor={colors.blue}
                                          style={{
                                            backgroundColor: colors.background,
                                            borderWidth: 2,
                                            color: colors.darkBlue,
                                            width: "100%",
                                            fontFamily: "Montserrat",
                                          }}
                                        ></TextInput>
                                      </Col>
                                    </Row>
                                  </animated.div>
                                )}
                              </Spring>
                              <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                                {(styles) => (
                                  <animated.div
                                    className="mt-3"
                                    style={{
                                      ...styles,
                                      borderRadius: 10,
                                      backgroundColor: colors.lightBlue,
                                      boxShadow: `0 0 10px ${colors.lightBlue}`,
                                      padding: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p
                                        className="mb-2"
                                        style={{
                                          fontFamily: "Manchego",
                                          color: colors.darkBlue,
                                          fontSize: "1.5em",
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        {t("cookCoverComponent.modifyLetter")}
                                      </p>
                                      {this.state.coverLetter
                                        ?.status_generation_letter !==
                                        "finished" && (
                                        <ThreeDots
                                          visible={
                                            !this.state.coverLetter
                                              ?.status_generation_letter !==
                                            "finished"
                                          }
                                          height="50%"
                                          width={40}
                                          color={colors.background}
                                          radius="9"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                        />
                                      )}
                                    </div>

                                    <TextInput
                                      disabled={
                                        this.state.coverLetter
                                          ?.status_generation_letter !==
                                        "finished"
                                      }
                                      as="textarea"
                                      animColor={colors.blue}
                                      onBlur={this.updateCoverLetter.bind(
                                        this,
                                        "letter"
                                      )}
                                      onChange={(e) => {
                                        this.setState({
                                          coverLetter: {
                                            ...this.state.coverLetter,
                                            letter: e.target.value,
                                          },
                                        });
                                      }}
                                      value={this.state.coverLetter?.letter}
                                      style={{
                                        opacity:
                                          this.state.coverLetter
                                            ?.status_generation_letter !==
                                          "finished"
                                            ? 0.5
                                            : 1,
                                        backgroundColor: colors.background,
                                        borderWidth: 2,
                                        fontSize: fontSizes.small,
                                        color: colors.darkBlue,
                                        width: "100%",
                                        fontFamily: "Montserrat",
                                        height: "200px",
                                      }}
                                    ></TextInput>
                                  </animated.div>
                                )}
                              </Spring>

                              <div
                                className="mt-3"
                                style={{
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <CustomButton
                                  onClick={() => {
                                    this.downloadPDF();
                                  }}
                                >
                                  <FiletypePdf size={25}></FiletypePdf>
                                </CustomButton>
                              </div>
                            </Col>
                            <Col
                              style={{
                                minHeight: 500,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              md={6}
                              xs={12}
                            >
                              {this.renderLetter()}
                            </Col>
                          </Row>
                        </div>
                      );
                    case "advices":
                      return (
                        <AutoSizer>
                          {({ height, width }) => (
                            <div
                              style={{
                                height: height,
                                width: width,
                                borderRadius: 10,
                                border: `2px solid ${colors.lightBlue}`,
                                padding: "10px",
                                overflowY: "auto",
                                position: "relative",
                              }}
                            >
                              {this.renderMarkdown(this.state.iAdvices.advice)}
                            </div>
                          )}
                        </AutoSizer>
                      );
                  }
                })()}
              </animated.div>
            )
          }
        </Transition>
      </div>
    );
  }
}

export default withTranslationHOC(CookCoverComponent);
