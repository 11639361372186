import React, { Component } from "react";
import { colors } from "../colors";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import CustomButton from "./Button";
import { updateUser, updatePassword, me } from "../api";
import { withTranslationHOC } from "../withTranslation";

const optionsLanguages = [
  { value: "", label: "" },
  { value: "FRENCH", label: "Français" },
  { value: "ENGLISH", label: "English" },
  { value: "SPANISH", label: "Español" },
  { value: "GERMAN", label: "Deutsch" },
  { value: "ITALIAN", label: "Italiano" },
  { value: "PORTUGUESE", label: "Português" },
  { value: "DUTCH", label: "Nederlands" },
  { value: "POLISH", label: "Polski" },
  { value: "RUSSIAN", label: "Русский" },
  { value: "CHINESE (ZH-CN)", label: "中文" },
];

class SetCookingSecret extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: "",
      default_language: "",
      password: "",
      passwordConfirm: "",
    };
  }

  updateGenderAndLanguageAndPassword = async () => {
    try {
      await updateUser(this.props.user.id, {
        gender: this.state.gender,
        default_language: this.state.default_language,
        email: this.state.email,
      });
      await updatePassword(this.state.email, this.state.password);
      this.props.continueAfterPasswordSet();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async componentDidMount() {
    try {
      const user = await me();
      console.log(user);
      this.setState({ email: user.email ? user.email : "" });
    } catch (error) {}
  }

  render() {
    const { t } = this.props;
    const optionsGender = [
      { value: "", label: "" },
      { value: "MALE", label: t("setCookingSecret.male") },
      { value: "FEMALE", label: t("setCookingSecret.female") },
      { value: "NON-BINARY", label: t("setCookingSecret.nonBinary") },
    ];

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderRadius: 10,
            backgroundColor: colors.lightBlue,
            boxShadow: `0 0 10px ${colors.lightBlue}`,
            padding: "10px",
          }}
        >
          <h1
            className="mb-2"
            style={{
              fontFamily: "Manchego",
              color: colors.darkBlue,
              fontSize: "1.5em",
              padding: 0,
              margin: 0,
            }}
          >
            {t("setCookingSecret.thankYouForYourPurchase")}
          </h1>
          <p
            className="mb-2"
            style={{
              fontFamily: "Montserrat",
              color: colors.darkBlue,
              padding: 0,
              margin: 0,
            }}
          >
            {t("setCookingSecret.defineYourGender")}
          </p>
          <SelectInput
            className="mb-2"
            onChange={(event) => this.setState({ gender: event.target.value })}
            options={optionsGender}
            animColor={colors.blue}
            style={{ backgroundColor: colors.background }}
          ></SelectInput>

          <p
            className="mb-2"
            style={{
              fontFamily: "Montserrat",
              color: colors.darkBlue,
              padding: 0,
              margin: 0,
            }}
          >
            {t("setCookingSecret.defaultLanguage")}
          </p>
          <SelectInput
            className="mb-2"
            onChange={(event) =>
              this.setState({ default_language: event.target.value })
            }
            options={optionsLanguages}
            animColor={colors.blue}
            style={{ backgroundColor: colors.background }}
          ></SelectInput>
          <p
            className="mb-2"
            style={{
              fontFamily: "Montserrat",
              color: colors.darkBlue,
              padding: 0,
              margin: 0,
            }}
          >
            {t("setCookingSecret.email")}
          </p>

          <TextInput
            onChange={(e) => this.setState({ email: e.target.value })}
            value={this.state.email}
            className="mt-2"
            animColor={colors.blue}
            style={{ backgroundColor: colors.background }}
            placeholder="Email"
            type="email"
          ></TextInput>
          <p
            className="mb-2 mt-2"
            style={{
              fontFamily: "Montserrat",
              color: colors.darkBlue,
              padding: 0,
              margin: 0,
            }}
          >
            {t("setCookingSecret.passwordForYourAccount")}
          </p>

          <TextInput
            onChange={(e) => this.setState({ password: e.target.value })}
            animColor={colors.blue}
            style={{ backgroundColor: colors.background }}
            placeholder={t("setCookingSecret.password")}
            type="password"
          ></TextInput>
          <TextInput
            onChange={(e) => this.setState({ passwordConfirm: e.target.value })}
            className="mt-2"
            animColor={colors.blue}
            style={{ backgroundColor: colors.background }}
            placeholder={t("setCookingSecret.passwordConfirmation")}
            type="password"
          ></TextInput>

          <CustomButton
            onClick={async () => {
              await this.updateGenderAndLanguageAndPassword();
            }}
            disabled={
              !this.state.gender ||
              !this.state.default_language ||
              this.state.password !== this.state.passwordConfirm ||
              this.state.password.length < 5 ||
              !this.state.email
            }
            className="mt-2"
          >
            {t("setCookingSecret.set")}
          </CustomButton>
        </div>
      </div>
    );
  }
}

export default withTranslationHOC(SetCookingSecret);
