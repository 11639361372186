import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { colors } from "../colors";
import CustomButton from "./Button";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { Clipboard2CheckFill } from "react-bootstrap-icons";
import { Spring, animated, Transition } from "@react-spring/web";
import AutoSizer from "react-virtualized-auto-sizer";
import { ThreeDots } from "react-loader-spinner";
import PayTheBillComponent from "./PayTheBillComponent";
import {
  downloadResume,
  generateTips,
  getCoverLetterById,
  getResumeById,
  me,
  uploadResumeForCoverLetter,
} from "../api";
import { withTranslationHOC } from "../withTranslation";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class CookResumeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: "0%",
      showPayment: false,
      showContinue: false,
      coverLetter: null,
      resume: null,
      fieldsOk: [],
    };
  }

  async loadResumeFile(resume) {
    try {
      const blob = await downloadResume(resume.id);
      const url = URL.createObjectURL(blob);
      resume.url = url;
      return resume;
    } catch (error) {
      console.error("Error downloading resume file:", error);
    }
  }

  launchAnalyseAnimation = () => {
    if (!this.interval)
      this.interval = setInterval(() => {
        const { top } = this.state;
        this.setState({ top: top === "0%" ? "100%" : "0%" });
      }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.statusResumeCheckInterval);
  }

  updateResumeFields(resume) {
    const fieldsOk = [];

    Object.keys(resume.tips).forEach((field) => {
      if (
        JSON.stringify(resume.tips[field]) ===
        JSON.stringify(this.state.resume.tips[field])
      ) {
        fieldsOk.push(field);
      }
    });
    this.setState({ fieldsOk });
  }

  async launchResumeAnalysis(resumeArg) {
    try {
      console.log("launching resume analysis");
      let resume = resumeArg;
      console.log("resume", resume);
      if (!resume.status_generation_tips) {
        try {
          resume = await generateTips(resume.id);
          console.log("lanched tips generarion:");
          this.props.getUser();
        } catch (error) {
          if (error.response && error.response.status === 402) {
            console.log("No more credit");
            if (
              this.state.user.credit_writing_letter > 0 &&
              !this.props.comesFromCooker
            ) {
              clearInterval(this.interval);

              this.setState({ showContinue: true });
            } else {
              clearInterval(this.interval);

              this.setState({ showPayment: true });
            }

            return;
          } else {
            console.error("Error generating tips for cover letter:", error);
          }
        }
      }

      if (resume.status_generation_tips !== "finished") {
        this.statusResumeCheckInterval = setInterval(async () => {
          try {
            const updatedResume = await getResumeById(resume.id);
            if (this.state.resume?.tips) this.updateResumeFields(updatedResume);
            updatedResume.url = resumeArg.url;

            this.setState({ resume: updatedResume });

            if (updatedResume.status_generation_tips === "finished") {
              this.setState({ resume: updatedResume }).then(() => {
                this.updateResumeFields(updatedResume);
              });

              clearInterval(this.statusResumeCheckInterval);
              clearInterval(this.interval);

              this.props.getUser();
            }
          } catch (error) {
            console.error("Error checking resume status:", error);
          }
        }, 1000);
      } else {
        clearInterval(this.statusResumeCheckInterval);
        this.setState({ resume });
      }
    } catch (error) {}
  }

  async componentDidMount() {
    try {
      let resumeId;
      if (!this.props.resumeSelectedId) {
        const coverLetter = await getCoverLetterById(this.props.coverLetterId);
        resumeId = coverLetter.resume_id;
      } else {
        resumeId = this.props.resumeSelectedId;
      }

      let resume = await getResumeById(resumeId);
      console.log("resume", resume);
      resume = await this.loadResumeFile(resume);
      this.setState({ resume });
      const user = await me();
      this.setState({ user });
      if (user && !user.on_boarding_completed) {
        console.log("user", user);
        this.launchAnalyseAnimation();

        setTimeout(() => {
          this.setState({ showPayment: true });
        }, 3000);
      } else {
        if (!resume.tips) {
          this.launchAnalyseAnimation();

          this.launchResumeAnalysis(resume);
        } else {
          const fieldsOk = Object.keys(resume.tips);
          this.setState({ fieldsOk });
        }
      }
    } catch (error) {}
  }

  initiateResumeUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf,.doc,.docx";
    input.onchange = async (e) => {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        try {
          await uploadResumeForCoverLetter(file, this.props.coverLetterId);
          this.props.changeStep(3);
        } catch (error) {
          console.error("Error uploading resume:", error);
        }
      }
    };
    input.click();
  };

  renderTransitionSection = (delay, title, mark, description, example, t) => {
    return (
      <Transition
        key={title}
        items={true}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
        config={{ duration: 300 }}
        delay={delay}
      >
        {(styles, item) =>
          item && (
            <animated.div
              className="mb-3"
              style={{
                ...styles,
                display: "flex",
                borderRadius: 10,
                border: `2px solid ${colors.lightBlue}`,
                padding: "10px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "Manchego",
                    color: colors.darkBlue,
                    fontSize: "1.5em",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {title}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    color: colors.white,
                    fontSize: "0.8em",
                    backgroundColor:
                      mark === "bad"
                        ? colors.red
                        : mark === "average"
                        ? colors.yellow
                        : mark === "good"
                        ? colors.green
                        : "grey",
                    borderRadius: 10,
                    height: 25,
                    padding: "5px",
                    margin: 0,
                  }}
                >
                  {mark}
                </p>
              </div>
              <p
                className="mb-2"
                style={{
                  fontFamily: "Montserrat",
                  color: colors.darkBlue,
                  fontSize: "0.8em",
                  padding: 0,
                  margin: 0,
                }}
              >
                {description}
              </p>
              {example && (
                <>
                  <p
                    style={{
                      fontFamily: "Manchego",
                      color: colors.darkBlue,
                      textAlign: "right",
                      width: "100%",
                      fontSize: "1em",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {t("cookResumeComponent.example")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: colors.lightBlue,
                      padding: "10px",
                      borderRadius: 10,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        color: colors.white,
                        fontSize: "0.8em",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {example}
                    </p>
                    <div className="ms-2">
                      <Clipboard2CheckFill
                        style={{
                          cursor: "pointer",
                        }}
                        color={colors.background}
                        onClick={() => {
                          navigator.clipboard.writeText(example);
                        }}
                      ></Clipboard2CheckFill>
                    </div>
                  </div>
                </>
              )}
            </animated.div>
          )
        }
      </Transition>
    );
  };

  renderResumeTips() {
    const { t } = this.props;
    const tipsSections = [
      { key: "overall", title: t("cookResumeComponent.general"), delay: 0 },
      {
        key: "objective_statement",
        title: t("cookResumeComponent.objectiveStatement"),
        delay: 200,
      },
      {
        key: "work_experiences",
        title: t("cookResumeComponent.workExperiences"),
        delay: 400,
      },
      {
        key: "education/certifications",
        title: t("cookResumeComponent.educationCertifications"),
        delay: 600,
      },
      { key: "skills", title: t("cookResumeComponent.skills"), delay: 800 },
      { key: "metrics", title: t("cookResumeComponent.metrics"), delay: 1000 },
      { key: "wording", title: t("cookResumeComponent.wording"), delay: 1200 },
    ];

    return tipsSections.map((section) => {
      const tip = this.state.resume?.tips?.[section.key];
      if (this.state.fieldsOk.indexOf(section.key) > -1 && tip) {
        return this.renderTransitionSection(
          section.delay,
          section.title,
          tip.mark,
          tip.description,
          tip.example,
          t
        );
      }
      return null;
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          position: "relative",
        }}
      >
        <Transition
          items={this.state.showPayment}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(styles, step) =>
            step ? (
              <animated.div
                style={{
                  ...styles,

                  position: "absolute",
                  zIndex: 200,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    backgroundColor: colors.background,
                    borderRadius: 10,
                  }}
                >
                  <PayTheBillComponent
                    getUser={this.props.getUser}
                    step={2}
                    comesFromCooker={this.props.comesFromCooker}
                    resumeId={this.state.resume?.id}
                    coverLetterId={this.props.coverLetterId}
                  />
                </div>
              </animated.div>
            ) : (
              <animated.div style={{ ...styles, display: "flex", flex: 1 }}>
                <Row style={{ flex: 1, gap: "20px" }}>
                  <Col
                    md={6}
                    xs={12}
                    style={{
                      borderRadius: 10,
                      alignContent: "flex-start",
                      flexDirection: "column",

                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden", // Changed from auto to hidden to ensure the document fits without scroll
                        scrollbarWidth: "none", // Hide scrollbar for all browsers
                        msOverflowStyle: "none", // IE and Edge
                        "::-webkit-scrollbar": {
                          display: "none", // Hide scrollbar for Chrome, Safari and Opera
                        },
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flex: 1,
                          height: "100%",
                          position: "relative",
                          maxHeight: "100%", // Ensure this div cannot grow larger than its parent
                          overflow: "hidden", // Hide any overflow
                        }}
                      >
                        {this.state.resume?.status_generation_tips !==
                          "finished" &&
                          !this.state.showContinue && (
                            <Spring top={this.state.top} duration={1000}>
                              {(styles) => (
                                <animated.div
                                  style={{
                                    ...styles,
                                    boxShadow: `0 0 10px ${colors.blue}`,
                                    position: "absolute",
                                    zIndex: 100,
                                    width: "100%",
                                    height: 10,
                                    backgroundColor: colors.blue,
                                  }}
                                ></animated.div>
                              )}
                            </Spring>
                          )}

                        <div
                          style={{
                            flex: 1,
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            opacity: this.state.resume?.tips ? 1 : 0.5,
                          }}
                        >
                          <AutoSizer>
                            {({ height, width }) => (
                              <Document
                                noData={""}
                                file={this.state.resume?.url}
                              >
                                <Page
                                  noData=""
                                  size="A4"
                                  pageNumber={1}
                                  width={width}
                                  height={height}
                                />
                              </Document>
                            )}
                          </AutoSizer>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      minHeight: 500,
                    }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        borderRadius: 10,
                        backgroundColor: colors.lightBlue,
                        boxShadow: `0 0 10px ${colors.lightBlue}`,
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {!this.props.comesFromCooker && (
                          <div
                            className="me-2"
                            style={{
                              display: "flex",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              backgroundColor: colors.darkBlue,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h4
                              style={{
                                color: colors.background,
                                fontFamily: "Manchego",
                                fontWeight: "bold",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              1
                            </h4>
                          </div>
                        )}

                        <p
                          style={{
                            fontFamily: "Manchego",
                            color: colors.darkBlue,
                            fontSize: "1.5em",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {t("cookResumeComponent.resumeAnalysis")}
                        </p>
                      </div>
                      <ThreeDots
                        visible={
                          this.state.resume?.status_generation_tips !==
                            "finished" && !this.state.showContinue
                        }
                        height="50%"
                        width={40}
                        color={colors.background}
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <AutoSizer>
                        {({ height, width }) => (
                          <div
                            style={{
                              height: height,
                              width: width,
                              overflow: "auto",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {this.state.showContinue ? (
                              <div>
                                <div
                                  style={{
                                    fontFamily: "Montserrat",
                                    color: colors.red,
                                  }}
                                >
                                  {t("cookResumeComponent.noCredit")}
                                </div>
                                <CustomButton
                                  onClick={() => {
                                    this.setState({
                                      showPayment: true,
                                      showContinue: false,
                                    });
                                  }}
                                  className="mt-2"
                                >
                                  {t("cookResumeComponent.buyCredits")}
                                </CustomButton>
                              </div>
                            ) : (
                              this.renderResumeTips()
                            )}
                          </div>
                        )}
                      </AutoSizer>
                    </div>
                    {!this.props.comesFromCooker && (
                      <>
                        <Spring
                          opacity={
                            this.state.resume?.status_generation_tips ===
                            "finished"
                              ? 1
                              : 0.5
                          }
                        >
                          {(styles) => (
                            <animated.div
                              className={"mb-2"}
                              style={{
                                ...styles,
                              }}
                            >
                              <CustomButton
                                disabled={
                                  this.state.resume?.status_generation_tips !==
                                  "finished"
                                }
                                onClick={this.initiateResumeUpload}
                              >
                                {t("cookResumeComponent.uploadCorrectedResume")}
                              </CustomButton>
                            </animated.div>
                          )}
                        </Spring>
                        <Spring
                          opacity={
                            this.state.resume?.status_generation_tips ===
                              "finished" || this.state.showContinue
                              ? 1
                              : 0.5
                          }
                        >
                          {(styles) => (
                            <animated.div
                              style={{
                                ...styles,
                              }}
                            >
                              <CustomButton
                                disabled={
                                  this.state.resume?.status_generation_tips !==
                                    "finished" && !this.state.showContinue
                                }
                                onClick={() => {
                                  this.props.changeStep(3);
                                }}
                              >
                                {t("cookResumeComponent.generateCoverLetter")}
                              </CustomButton>
                            </animated.div>
                          )}
                        </Spring>
                      </>
                    )}
                  </Col>
                </Row>
              </animated.div>
            )
          }
        </Transition>
      </div>
    );
  }
}

export default withTranslationHOC(CookResumeComponent);
