import React, { Component } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { colors } from "../colors";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import ButtonLanding from "../components/ButtonLanding";

import { ToastContainer, toast } from "react-toastify";
import { createUser, loginUser, me } from "../api";
import { withRouter } from "../withRouter";
import { withTranslationHOC } from "../withTranslation";

import { fontSizes } from "../fontSizes";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailNew: "",
    };
  }

  async loginUser() {
    const { t } = this.props;

    try {
      const user = await loginUser(this.state.email, this.state.password);
      localStorage.setItem("token", user.access_token);

      this.props.navigate("/cooking");
    } catch (error) {
      toast.error(t("loginPage.invalidEmailOrPassword"), {
        style: {
          backgroundColor: colors.red,
          color: "white",
          fontFamily: "Montserrat",
        },
        progressStyle: {
          backgroundColor: colors.darkBlue,
        },
      });
      this.setState({ email: "", password: "" });
      console.error("Error:", error);
    }
  }

  async createAccount() {
    const { t } = this.props;

    try {
      try {
        const user = await me();

        this.props.navigate("/cooking");
        return;
      } catch (error) {
        const token = await createUser();
        localStorage.setItem("token", token.access_token);
        console.log(token.access_token);
        this.props.navigate("/cooking");
        return;
      }
    } catch (error) {
      toast.error(t("loginPage.errorOccured"), {
        style: {
          backgroundColor: colors.red,
          color: "white",
          fontFamily: "Montserrat",
        },
        progressStyle: {
          backgroundColor: colors.darkBlue,
        },
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <ToastContainer hideProgressBar={true} />

        <Navbar
          style={{ backgroundColor: colors.lightBlue, height: 64 }}
          expand="lg"
        >
          <Container>
            <Navbar.Brand
              style={{ fontFamily: "Manchego", color: colors.darkBlue }}
              href="/"
            >
              CoverCooker<span style={{ color: colors.background }}>.ai</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto"></Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          style={{
            display: "flex",
            flex: 1,
            height: "calc(100% - 64px)",
            width: "100%",
            backgroundColor: colors.background,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderRadius: 10,
              backgroundColor: colors.lightBlue,
              boxShadow: `0 0 10px ${colors.lightBlue}`,
              padding: "10px",
              width: "100%",
              maxWidth: 400,
            }}
          >
            <div
              style={{
                fontFamily: "Manchego",
                color: colors.darkBlue,
                fontSize: fontSizes.large,
              }}
            >
              CoverCooker<span style={{ color: colors.background }}>.ai</span>
            </div>
            <p
              className="mb-2 mt-2"
              style={{
                fontFamily: "Montserrat",
                color: colors.darkBlue,
                padding: 0,
                margin: 0,
              }}
            >
              {t("loginPage.alreadyAccount")}
            </p>
            <TextInput
              onChange={(e) => this.setState({ email: e.target.value })}
              animColor={colors.blue}
              value={this.state.email}
              style={{ backgroundColor: colors.background }}
              placeholder={t("loginPage.email")}
              type="email"
            ></TextInput>
            <TextInput
              onChange={(e) => this.setState({ password: e.target.value })}
              className="mt-2"
              value={this.state.password}
              animColor={colors.blue}
              style={{ backgroundColor: colors.background }}
              placeholder={t("loginPage.password")}
              type="password"
            ></TextInput>
            <Button
              onClick={async () => {
                await this.loginUser();
              }}
              disabled={
                this.state.password.length === 0 ||
                this.state.email.length === 0
              }
              className="mt-2"
            >
              {t("loginPage.connect")}
            </Button>
            <p
              className="mb-2 mt-3"
              style={{
                fontFamily: "Montserrat",
                color: colors.darkBlue,
                padding: 0,
                margin: 0,
              }}
            >
              {t("loginPage.noAccount")}
            </p>

            <ButtonLanding
              onClick={async () => {
                await this.createAccount();
              }}
              className="mt-2"
            >
              {t("loginPage.generateLetter")}
            </ButtonLanding>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslationHOC(LoginPage));
