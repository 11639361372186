import React, { Component } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { colors } from "../colors";
import Button from "./Button";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { X, FileEarmarkArrowUpFill } from "react-bootstrap-icons";

import { withTranslationHOC } from "../withTranslation";

import TextInput from "./TextInput";
import { Spring, animated, Transition } from "@react-spring/web";
import {
  createCoverLetter,
  downloadResume,
  getResumesByUser,
  uploadResume,
} from "../api";

import AutoSizer from "react-virtualized-auto-sizer";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class IngredientsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfSelected: null,
      loadingFile: false,
      langageSelected: null,
      entityName: "",
      entityType: "COMPANY",
      positionTitle: "",
      offerDescription: "",
      toneGenerationLetter: "PROFESSIONAL",

      pdfFiles: [],
      languages: [
        { id: 1, name: "ingredientsComponent.langFrench", code: "FRENCH" },
        { id: 2, name: "ingredientsComponent.langEnglish", code: "ENGLISH" },
        { id: 3, name: "ingredientsComponent.langSpanish", code: "SPANISH" },
        { id: 4, name: "ingredientsComponent.langGerman", code: "GERMAN" },
        { id: 5, name: "ingredientsComponent.langItalian", code: "ITALIAN" },
        {
          id: 6,
          name: "ingredientsComponent.langPortuguese",
          code: "PORTUGUESE",
        },
        { id: 7, name: "ingredientsComponent.langDutch", code: "DUTCH" },
        { id: 8, name: "ingredientsComponent.langPolish", code: "POLISH" },
        { id: 9, name: "ingredientsComponent.langRussian", code: "RUSSIAN" },
        {
          id: 10,
          name: "ingredientsComponent.langChinese",
          code: "CHINESE (ZH-CN)",
        },
        { id: 11, name: "ingredientsComponent.langArabic", code: "ARABIC" },
      ],

      optionsEntityTypes: [
        { value: "COMPANY", label: "ingredientsComponent.entityCompany" },
        { value: "INTERNSHIP", label: "ingredientsComponent.entityInternship" },
        {
          value: "WORK/STUDY TRAINING PROGRAM",
          label: "ingredientsComponent.entityTrainingProgram",
        },
        { value: "SCHOOL", label: "ingredientsComponent.entitySchool" },
        {
          value: "TRAINING COURSE",
          label: "ingredientsComponent.entityTrainingCourse",
        },
      ],

      optionsTones: [
        {
          value: "PROFESSIONAL",
          label: "ingredientsComponent.toneProfessional",
        },
        { value: "FRIENDLY", label: "ingredientsComponent.toneFriendly" },
        { value: "HUMOROUS", label: "ingredientsComponent.toneHumorous" },
        //{ value: "POETIC", label: "ingredientsComponent.tonePoetic" },
      ],
    };
  }

  verifyInfoComplete = () => {
    return (
      this.state.entityName.length > 0 &&
      this.state.positionTitle.length > 0 &&
      this.state.offerDescription.length > 0 &&
      this.state.langageSelected !== null
    );
  };

  getResumes = async () => {
    try {
      const response = await getResumesByUser();

      for (const resume of response) {
        const blob = await downloadResume(resume.id);
        const url = URL.createObjectURL(blob);
        resume.url = url;
      }

      let pdfSelected = null;
      if (this.props.resumeSelectedId) {
        console.log("resumeSelectedId", this.props.resumeSelectedId);
        pdfSelected = response.find(
          (pdf) => pdf.id === this.props.resumeSelectedId
        );
      }
      this.setState({ pdfFiles: response, pdfSelected: pdfSelected });
    } catch (error) {
      console.error("Error during fetching CVs:", error);
      throw error;
    }
  };
  uploadResume = async () => {
    // Open file dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = async (e) => {
      if (e.target.files.length > 0) {
        this.setState({ loadingFile: true });
        const file = e.target.files[0];
        try {
          const response = await uploadResume(file);

          const blob = await downloadResume(response.id);
          const url = URL.createObjectURL(blob);
          response.url = url;
          this.setState((state) => ({
            pdfFiles: [...state.pdfFiles, response],
          }));

          this.setState({ pdfSelected: response });

          console.log(response);
        } catch (error) {
          console.error("Error uploading resume:", error);
        }
      }
      this.setState({ loadingFile: false });
    };
    input.onclick = () => {
      document.body.onfocus = () => {
        setTimeout(() => {
          if (input.value.length === 0) {
            this.setState({ loadingFile: false });
          }
          document.body.onfocus = null;
        }, 500);
      };
    };
    input.click();
  };
  componentDidMount() {
    this.getResumes();
  }

  createLetter = async () => {
    try {
      const coverLetter = await createCoverLetter(this.state.pdfSelected.id, {
        entity_name: this.state.entityName,
        entity_type: this.state.entityType ? this.state.entityType : "COMPANY",
        position: this.state.positionTitle,
        offer_description: this.state.offerDescription,
        tone_generation_letter: this.state.toneGenerationLetter
          ? this.state.toneGenerationLetter
          : "PROFESSIONAL",
        language: this.state.langageSelected.code,
      });

      this.props.changeCoverLetterId(coverLetter.id);
    } catch (error) {
      console.error("Error during creating cover letter:", error);
    }
  };

  renderPdf = () => {
    let pdfFiles = [];
    const sortedPdfs = [...this.state.pdfFiles].sort(
      (a, b) => a.created_at < b.created_at
    );
    sortedPdfs.map((pdf) => {
      pdfFiles.push(
        <Spring
          to={{
            boxShadow:
              this.state.pdfHover === pdf.id
                ? `0 0 10px ${colors.lightBlue}`
                : `0 0 0px ${colors.lightBlue}`,
          }}
        >
          {(styles) => (
            <animated.div
              onClick={() => {
                this.setState({ pdfSelected: pdf });
              }}
              onMouseEnter={() => {
                this.setState({ pdfHover: pdf.id });
              }}
              onMouseLeave={() => {
                this.setState({ pdfHover: null });
              }}
              style={{
                ...styles,
                cursor: "pointer",
                borderRadius: 10,
                overflow: "hidden",
                height: 200,
                width: 200 / 1.41,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Document
                noData={""}
                loading={
                  <div
                    style={{
                      flex: 1,
                      height: 200,
                      width: 200 / 1.41,
                      display: "flex",

                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: colors.lightBlue,
                    }}
                  >
                    <Spinner
                      variant="light"
                      animation="border"
                      role="status"
                      size="sm"
                    ></Spinner>
                  </div>
                }
                file={pdf.url}
              >
                <Page noData={""} height={200} pageNumber={1} />
              </Document>
            </animated.div>
          )}
        </Spring>
      );
    });

    pdfFiles.push(
      <div
        onClick={this.uploadResume}
        style={{
          cursor: "pointer",
          backgroundColor: colors.blue,
          borderRadius: 10,
          overflow: "hidden",
          height: 200,
          width: 200 / 1.41,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `0 0 10px ${colors.lightBlue}`,
        }}
      >
        {this.state.loadingFile ? (
          <Spinner variant="light"></Spinner>
        ) : (
          <FileEarmarkArrowUpFill size={50} color={colors.background} />
        )}
      </div>
    );

    return pdfFiles;
  };

  render() {
    const { t } = this.props;

    return (
      <div style={{ display: "flex", flex: 1 }}>
        <Row style={{ flex: 1, gap: "20px" }}>
          <Col
            md={6}
            style={{
              display: "flex", // Utilisez flex pour un layout flexible
              flexWrap: "wrap", // Permet aux éléments de passer à la ligne suivante si nécessaire
              border: "2px solid " + colors.blue,
              borderRadius: 10,
              padding: "10px",
              alignContent: "flex-start",
              flexDirection: "column",

              position: "relative",
            }}
          >
            {this.state.pdfSelected ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  zIndex: 100,
                  right: 10,
                  top: 10,
                  alignItems: "center",
                  borderRadius: 30,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: 50,
                    height: 50,
                  }}
                >
                  <Button
                    onClick={() => {
                      this.setState({ pdfSelected: null });
                    }}
                  >
                    <X size={20} color={colors.white} />
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: 50,
                }}
              >
                <span
                  style={{
                    fontFamily: "Manchego",
                    color: colors.darkBlue,
                    fontSize: "1.5em",
                  }}
                >
                  {t("ingredientsComponent.selectResume")}
                </span>
              </div>
            )}
            <Transition
              items={this.state.pdfSelected}
              config={{ duration: 200 }}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
              onRest={() => {}}
              exitBeforeEnter={true}
            >
              {(styles, item) =>
                item ? (
                  <animated.div
                    style={{
                      ...styles,
                      display: "flex",
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      overflow: "auto",
                      scrollbarWidth: "none", // Hide scrollbar for all browsers
                      msOverflowStyle: "none", // IE and Edge
                      "::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar for Chrome, Safari and Opera
                      },
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        height: 400,
                        width: 400 / 1.41,
                      }}
                    >
                      <Document
                        noData={""}
                        loading={
                          <div
                            style={{
                              flex: 1,
                              height: 200,
                              width: 200 / 1.41,
                              display: "flex",

                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: colors.lightBlue,
                            }}
                          >
                            <Spinner
                              variant="light"
                              animation="border"
                              role="status"
                              size="sm"
                            ></Spinner>
                          </div>
                        }
                        file={this.state.pdfSelected?.url}
                      >
                        <Page noData={""} pageNumber={1} />
                      </Document>
                    </div>
                  </animated.div>
                ) : (
                  <animated.div
                    style={{
                      ...styles,
                      flex: 1,
                      display: "flex", // Utilisez flex pour un layout flexible
                      flexWrap: "wrap", // Permet aux éléments de passer à la ligne suivante si nécessaire
                      gap: "10px", // Ajoutez un espace entre les
                      alignContent: "flex-start",
                    }}
                  >
                    {this.renderPdf()}
                  </animated.div>
                )
              }
            </Transition>
          </Col>
          <Col
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              minHeight: 500,
              padding: 0,
              opacity: this.state.pdfSelected ? 1 : 0.5,
            }}
          >
            <Spring
              opacity={this.state.pdfSelected ? 1 : 0}
              marginTop={this.state.pdfSelected ? 0 : 20}
            >
              {(styles) => (
                <animated.div
                  className="mb-3"
                  style={{
                    ...styles,
                    borderRadius: 10,
                    display: "flex",
                    backgroundColor: colors.lightBlue,
                    flexDirection: "row",
                    height: 50,
                    margin: 0,
                    position: "relative",
                  }}
                >
                  {this.state.optionsEntityTypes.map((entityType) => (
                    <Spring
                      boxShadow={
                        this.state.entityOptionHover === entityType.value
                          ? `0 0 10px ${colors.lightBlue}`
                          : `0 0 0px ${colors.lightBlue}`
                      }
                    >
                      {(styles) => (
                        <animated.div
                          onMouseEnter={() => {
                            this.setState({
                              entityOptionHover: entityType.value,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({ entityOptionHover: null });
                          }}
                          onClick={() => {
                            this.setState({ entityType: entityType.value });
                          }}
                          style={{
                            ...styles,
                            display: "flex",
                            borderRadius: 10,
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            fontFamily: "Manchego",
                            color: colors.background,
                          }}
                        >
                          <div style={{ zIndex: 2 }}>{t(entityType.label)}</div>
                        </animated.div>
                      )}
                    </Spring>
                  ))}
                  <Spring
                    left={
                      this.state.optionsEntityTypes.findIndex(
                        (option) => option.value === this.state.entityType
                      ) *
                        (100 / this.state.optionsEntityTypes.length) +
                      "%"
                    }
                  >
                    {(styles) => (
                      <animated.div
                        style={{
                          ...styles,
                          zIndex: 0,
                          position: "absolute",
                          boxShadow: `0 0 10px ${colors.darkBlue}`,

                          backgroundColor: colors.darkBlue,
                          display: "flex",
                          width:
                            100 / this.state.optionsEntityTypes.length + "%",
                          height: "100%",
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Manchego",
                          color: colors.darkBlue,
                        }}
                      ></animated.div>
                    )}
                  </Spring>
                </animated.div>
              )}
            </Spring>
            <div style={{ flex: 1 }}>
              <AutoSizer>
                {({ height, width }) => (
                  <div style={{ height, width, overflowY: "auto" }}>
                    <Spring
                      opacity={this.state.pdfSelected ? 1 : 0}
                      marginTop={this.state.pdfSelected ? 0 : 20}
                    >
                      {(styles) => (
                        <animated.div
                          className="mb-3"
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                            margin: 0,
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {this.state.entityType === "SCHOOL" ||
                            this.state.entityType === "TRAINING COURSE"
                              ? t("ingredientsComponent.establishementName")
                              : t("ingredientsComponent.companyName")}
                          </p>

                          <TextInput
                            onChange={(e) => {
                              this.setState({ entityName: e.target.value });
                            }}
                            value={this.state.entityName}
                            placeholder={
                              this.state.entityType === "SCHOOL" ||
                              this.state.entityType === "TRAINING COURSE"
                                ? t("ingredientsComponent.exampleUniversity")
                                : t("ingredientsComponent.exampleGoogle")
                            }
                            animColor={colors.blue}
                            style={{
                              backgroundColor: colors.background,
                              borderWidth: 2,
                              color: colors.darkBlue,

                              width: "100%",
                              fontFamily: "Montserrat",
                            }}
                          ></TextInput>
                        </animated.div>
                      )}
                    </Spring>
                    <Spring
                      opacity={this.state.pdfSelected ? 1 : 0}
                      marginTop={this.state.pdfSelected ? 0 : 20}
                    >
                      {(styles) => (
                        <animated.div
                          className="mb-3"
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                            margin: 0,
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {this.state.entityType === "SCHOOL" ||
                            this.state.entityType === "TRAINING COURSE"
                              ? t("ingredientsComponent.trainingTitle")
                              : t("ingredientsComponent.jobTitle")}
                          </p>

                          <TextInput
                            onChange={(e) => {
                              this.setState({ positionTitle: e.target.value });
                            }}
                            value={this.state.positionTitle}
                            placeholder={
                              this.state.entityType === "SCHOOL" ||
                              this.state.entityType === "TRAINING COURSE"
                                ? t(
                                    "ingredientsComponent.exampleInternationalLawLicense"
                                  )
                                : t("ingredientsComponent.exampleWebDeveloper")
                            }
                            animColor={colors.blue}
                            style={{
                              backgroundColor: colors.background,
                              borderWidth: 2,
                              color: colors.darkBlue,

                              width: "100%",
                              fontFamily: "Montserrat",
                            }}
                          ></TextInput>
                        </animated.div>
                      )}
                    </Spring>
                    <Spring
                      opacity={this.state.pdfSelected ? 1 : 0}
                      marginTop={this.state.pdfSelected ? 0 : 20}
                    >
                      {(styles) => (
                        <animated.div
                          className="mb-3"
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {this.state.entityType === "SCHOOL" ||
                            this.state.entityType === "TRAINING COURSE"
                              ? t("ingredientsComponent.trainingOffer")
                              : t("ingredientsComponent.jobOffer")}
                          </p>

                          <TextInput
                            onChange={(e) => {
                              this.setState({
                                offerDescription: e.target.value,
                              });
                            }}
                            value={this.state.offerDescription}
                            placeholder={
                              this.state.entityType === "SCHOOL" ||
                              this.state.entityType === "TRAINING COURSE"
                                ? t("ingredientsComponent.exampleSchool")
                                : t("ingredientsComponent.exampleTraining")
                            }
                            as="textarea"
                            animColor={colors.blue}
                            style={{
                              height: 200,
                              backgroundColor: colors.background,
                              borderWidth: 2,
                              color: colors.darkBlue,
                              width: "100%",
                              fontFamily: "Montserrat",
                            }}
                          ></TextInput>
                        </animated.div>
                      )}
                    </Spring>
                    <Spring
                      opacity={this.state.pdfSelected ? 1 : 0}
                      marginTop={this.state.pdfSelected ? 0 : 20}
                    >
                      {(styles) => (
                        <animated.div
                          className="mb-3"
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {t("ingredientsComponent.letterTone")}
                          </p>

                          <div
                            className="mb-3"
                            style={{
                              ...styles,
                              borderRadius: 10,
                              display: "flex",
                              backgroundColor: colors.background,
                              flexDirection: "row",
                              height: 50,
                              margin: 0,
                              position: "relative",
                            }}
                          >
                            {this.state.optionsTones.map((tone) => (
                              <Spring
                                boxShadow={
                                  this.state.toneGenerationLetter === tone.value
                                    ? `0 0 10px ${colors.background}`
                                    : `0 0 0px ${colors.background}`
                                }
                              >
                                {(styles) => (
                                  <animated.div
                                    onMouseEnter={() => {
                                      this.setState({
                                        toneOptionHover: tone.value,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({ toneOptionHover: null });
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        toneGenerationLetter: tone.value,
                                      });
                                    }}
                                    style={{
                                      ...styles,
                                      display: "flex",
                                      borderRadius: 10,
                                      flex: 1,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      fontFamily: "Manchego",
                                      color: colors.darkBlue,
                                    }}
                                  >
                                    <div style={{ zIndex: 2 }}>
                                      {t(tone.label)}
                                    </div>
                                  </animated.div>
                                )}
                              </Spring>
                            ))}
                            <Spring
                              left={
                                this.state.optionsTones.findIndex(
                                  (option) =>
                                    option.value ===
                                    this.state.toneGenerationLetter
                                ) *
                                  (100 / this.state.optionsTones.length) +
                                "%"
                              }
                            >
                              {(styles) => (
                                <animated.div
                                  style={{
                                    ...styles,
                                    zIndex: 0,
                                    position: "absolute",
                                    boxShadow: `0 0 10px ${colors.lightBlue}`,

                                    backgroundColor: colors.lightBlue,
                                    display: "flex",
                                    width:
                                      100 / this.state.optionsTones.length +
                                      "%",
                                    height: "100%",
                                    borderRadius: 10,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontFamily: "Manchego",
                                    color: colors.darkBlue,
                                  }}
                                ></animated.div>
                              )}
                            </Spring>
                          </div>
                        </animated.div>
                      )}
                    </Spring>
                    <Spring
                      opacity={this.state.pdfSelected ? 1 : 0}
                      marginTop={this.state.pdfSelected ? 0 : 20}
                    >
                      {(styles) => (
                        <animated.div
                          style={{
                            ...styles,
                            borderRadius: 10,
                            backgroundColor: colors.lightBlue,
                            boxShadow: `0 0 10px ${colors.lightBlue}`,
                            padding: "10px",
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              fontFamily: "Manchego",
                              color: colors.darkBlue,
                              fontSize: "1.5em",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {t("ingredientsComponent.letterLanguage")}
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                              width: "100%",
                              borderRadius: 10,
                              alignContent: "flex-start",
                              padding: 0,
                            }}
                          >
                            {this.state.languages.map((language) => (
                              <Spring
                                key={language.id}
                                to={{
                                  outlineWidth:
                                    this.state.langageHover === language.id
                                      ? 2
                                      : 0,
                                  backgroundColor:
                                    this.state.langageSelected?.id ===
                                    language.id
                                      ? colors.darkBlue
                                      : colors.background,
                                }}
                              >
                                {(styles) => (
                                  <animated.div
                                    onMouseEnter={() => {
                                      this.setState({
                                        langageHover: language.id,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({ langageHover: null });
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        langageSelected: language,
                                      });
                                    }}
                                    style={{
                                      ...styles,
                                      outlineColor: colors.blue,
                                      outlineStyle: "solid",
                                      cursor: "pointer",
                                      display: "flex",
                                      borderRadius: 10,
                                      height: 30,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "10px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Montserrat",
                                        color:
                                          this.state.langageSelected?.id ===
                                          language.id
                                            ? colors.white
                                            : colors.darkBlue,
                                        fontSize: "1em",
                                      }}
                                    >
                                      {t(language.name)}
                                    </span>
                                  </animated.div>
                                )}
                              </Spring>
                            ))}
                          </div>
                        </animated.div>
                      )}
                    </Spring>
                  </div>
                )}
              </AutoSizer>
            </div>

            <Spring opacity={this.verifyInfoComplete() ? 1 : 0.5}>
              {(styles) => (
                <animated.div
                  className="mt-3"
                  style={{
                    ...styles,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    disabled={!this.verifyInfoComplete()}
                    onClick={async () => {
                      await this.createLetter();
                      this.props.changeStep(2);
                    }}
                  >
                    LET HIM COOK
                  </Button>
                </animated.div>
              )}
            </Spring>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslationHOC(IngredientsComponent);
