import React, { Component } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { colors } from "../colors";
import CustomButton from "./Button";
import { Check } from "react-bootstrap-icons";
import { loadStripe } from "@stripe/stripe-js";
import { PatchCheckFill } from "react-bootstrap-icons";
import { StarFill, StarHalf, LockFill } from "react-bootstrap-icons";
import { createCheckoutSession, getPackages, redeemPromo } from "../api";
import TextInput from "./TextInput";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "../withRouter";
import { withTranslationHOC } from "../withTranslation";

import { STRIPE_KEY } from "../config";

const planStyle = {
  backgroundColor: colors.lightBlue,
  borderRadius: "8px",
  padding: "25px",
  marginBottom: "20px",
  boxShadow: `0 0 10px ${colors.blue}`,
  margin: "10px",
};

const commentsStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderColor: colors.lightBlue,
  height: 300,
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "8px",
  padding: "25px",
  marginBottom: "20px",
  boxShadow: `0 0 10px ${colors.blue}`,
  margin: "10px",
};

const priceStyle = {
  color: "#e74c3c",
  fontSize: "24px",
  fontWeight: "bold",
  marginBottom: "10px",
};

const buttonStyle = {
  backgroundColor: "#e74c3c",
  borderColor: "#e74c3c",
  color: "#fff",
  padding: "10px 15px",
  fontSize: "16px",
  lineHeight: "1.5",
  borderRadius: "4px",
};

const featureListStyle = {
  listStyleType: "none",
  paddingLeft: "0",
  lineHeight: "1.6",
  fontFamily: "Montserrat",
  color: colors.darkBlue,
  fontSize: "14px",
};

class PayTheBillComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      loading: true,
      promoCode: "",
    };
  }

  redeemPromo = async () => {
    const { t } = this.props;

    try {
      const response = await redeemPromo(this.state.promoCode);
      if (response.used_credits) {
        window.location.href = `/cooking?step=${this.props.step}&cover_letter_id=${this.props.coverLetterId}&resume_id=${this.props.resumedId}&interview_advice_id=${this.props.interviewAdviceId}&comes_from_cooker=${this.props.comesFromCooker}`;
      }
      const packages = await getPackages();
      this.setState({ promoCode: "", packages: packages });
      await this.props.getUser();
      toast.success(t("payTheBillComponent.promoApplied"), {
        style: {
          backgroundColor: colors.lightBlue,
          color: "white",
          fontFamily: "Montserrat",
        },
        progressStyle: {
          backgroundColor: colors.darkBlue,
        },
      });
    } catch (error) {
      toast.error(t("payTheBillComponent.promoInvalid"), {
        style: {
          backgroundColor: colors.red,
          color: "white",
          fontFamily: "Montserrat",
        },
        progressStyle: {
          backgroundColor: colors.darkBlue,
        },
      });
      console.error("Error during promo code redemption:", error);
      this.setState({ promoCode: "" });
    }
  };

  getPackages = async () => {
    try {
      this.setState({ loading: true });
      const response = await getPackages();

      this.setState({ packages: response, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error("Error during fetching packages:", error);
      throw error;
    }
  };

  componentDidMount() {
    this.getPackages();
  }

  async buy(packageId) {
    try {
      const checkoutSession = await createCheckoutSession(
        {
          cover_letter_id: this.props.coverLetterId,
          resume_id: this.props.resumeId,
          interview_advice_id: this.props.interviewAdviceId,
          comes_from_cooker: this.props.comesFromCooker,
          step: this.props.step,
        },
        packageId
      );

      const sessionId = checkoutSession.session_id;

      const stripe = await loadStripe(STRIPE_KEY);

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });
      if (error) {
        console.error("Error during payment intent creation:", error);
        throw error;
      }
    } catch (error) {
      console.error("Error during payment intent creation:", error);
      throw error;
    }
  }

  renderPackages = () => {
    const { t } = this.props;

    return this.state.packages.map((pkg, index) => (
      <Col key={index} md={4} xs={12}>
        <div style={planStyle}>
          <h3
            style={{
              fontFamily: "Manchego",
              color: colors.darkBlue,
              marginBottom: "0px",
              display: "flex",
            }}
          >
            {t("payTheBillComponent." + pkg.name)}
          </h3>
          <span
            style={{
              fontFamily: "Montserrat",
              fontWeight: "medium",
              color: colors.darkBlue,
              fontSize: "0.9em",
              margin: 0,
              padding: 0,
            }}
          >
            {t("payTheBillComponent." + pkg.description)}
          </span>
          <div
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontFamily: "Manchego",
                color: colors.darkBlue,
                fontSize: "36px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {parseFloat(pkg.price).toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
              })}
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  fontFamily: "Montserrat",
                  width: 30,
                  color: colors.darkBlue,
                  fontSize: "12px",
                  fontWeight: "medium",
                  marginTop: "0px",
                  padding: 0,
                  marginLeft: "5px",
                }}
              >
                {t("payTheBillComponent." + pkg.unit)}
              </p>
            </div>
          </div>
          <CustomButton
            onClick={this.buy.bind(this, pkg.id)}
            style={buttonStyle}
          >
            {t("payTheBillComponent.buyPackage")}
          </CustomButton>
          <h5
            className="mt-3"
            style={{
              fontFamily: "Manchego",
              color: colors.darkBlue,
              marginBottom: "0px",
            }}
          >
            {t("payTheBillComponent.packageIncludesText")}
          </h5>
          <ul style={featureListStyle}>
            <li key={1}>
              <PatchCheckFill
                size={14}
                color={colors.background}
                className="me-1"
              />
              {t("payTheBillComponent.packageIncludes" + (index + 1) + "_1")}
            </li>
            <li key={2}>
              <PatchCheckFill
                size={14}
                color={colors.background}
                className="me-1"
              />
              {t("payTheBillComponent.packageIncludes" + (index + 1) + "_2")}
            </li>
            <li key={3}>
              <PatchCheckFill
                size={14}
                color={colors.background}
                className="me-1"
              />
              {t("payTheBillComponent.packageIncludes" + (index + 1) + "_3")}
            </li>
          </ul>
        </div>
      </Col>
    ));
  };

  render() {
    const { t } = this.props;

    return (
      <div
        style={{
          flex: 1,

          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "40px",
          position: "relative",
        }}
      >
        <ToastContainer hideProgressBar={true} />

        <div style={{ position: "absolute", left: 0, top: 0 }}>
          <LockFill
            size={10}
            color={colors.darkBlue}
            className="me-1"
          ></LockFill>
          <span
            style={{
              fontFamily: "Montserrat",
              color: colors.darkBlue,

              fontSize: "14px",
            }}
          >
            {t("payTheBillComponent.paymentSecured")}{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              stripe
            </span>
          </span>
        </div>
        {this.state.loading ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Row className="mb-1 d-flex justify-content-end ">
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <TextInput
                  onChange={(e) => {
                    this.setState({ promoCode: e.target.value });
                  }}
                  value={this.state.promoCode}
                  placeholder={t("payTheBillComponent.promoCode")}
                  animColor={colors.blue}
                  style={{
                    backgroundColor: colors.background,
                    borderWidth: 2,
                    color: colors.darkBlue,
                    width: "100%",
                    fontFamily: "Montserrat",
                  }}
                ></TextInput>
                <div className="ps-2" style={{ width: 50 }}>
                  <CustomButton
                    onClick={this.redeemPromo.bind(this)}
                    disabled={this.state.promoCode.length < 3}
                  >
                    <Check></Check>
                  </CustomButton>
                </div>
              </Col>
            </Row>
            <Row
              className="mb-1 "
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.renderPackages()}
            </Row>

            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col md={4} xs={12}>
                <div style={commentsStyle}>
                  <div>
                    {[...Array(5)].map((_, i) => (
                      <StarFill
                        key={i}
                        size={24}
                        color={colors.red}
                        className="me-1"
                      />
                    ))}
                    <p
                      className="mt-3"
                      style={{
                        fontFamily: "Montserrat",
                        color: colors.darkBlue,
                      }}
                    >
                      {t("payTheBillComponent.opinion1")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ fontFamily: "Manchego", color: colors.darkBlue }}
                    >
                      {t("payTheBillComponent.opinionAuthor1")}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PatchCheckFill
                        size={10}
                        color={colors.darkBlue}
                      ></PatchCheckFill>
                      <span
                        className="ms-1"
                        style={{
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          color: colors.darkBlue,
                        }}
                      >
                        {t("payTheBillComponent.verifiedPayment")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={4} xs={12}>
                <div style={commentsStyle}>
                  <div>
                    {[...Array(5)].map((_, i) => (
                      <StarFill
                        key={i}
                        size={24}
                        color={colors.red}
                        className="me-1"
                      />
                    ))}
                    <p
                      className="mt-3"
                      style={{
                        fontFamily: "Montserrat",
                        color: colors.darkBlue,
                      }}
                    >
                      {t("payTheBillComponent.opinion2")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ fontFamily: "Manchego", color: colors.darkBlue }}
                    >
                      {t("payTheBillComponent.opinionAuthor2")}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PatchCheckFill
                        size={10}
                        color={colors.darkBlue}
                      ></PatchCheckFill>
                      <span
                        className="ms-1"
                        style={{
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          color: colors.darkBlue,
                        }}
                      >
                        {t("payTheBillComponent.verifiedPayment")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div style={commentsStyle}>
                  <div>
                    {[...Array(4)].map((_, i) => (
                      <StarFill
                        key={i}
                        size={24}
                        color={colors.red}
                        className="me-1"
                      />
                    ))}
                    <StarHalf
                      key={5}
                      size={24}
                      color={colors.red}
                      className="me-1"
                    />
                    <p
                      className="mt-3"
                      style={{
                        fontFamily: "Montserrat",
                        color: colors.darkBlue,
                      }}
                    >
                      {t("payTheBillComponent.opinion3")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ fontFamily: "Manchego", color: colors.darkBlue }}
                    >
                      {t("payTheBillComponent.opinionAuthor3")}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PatchCheckFill
                        size={10}
                        color={colors.darkBlue}
                      ></PatchCheckFill>
                      <span
                        className="ms-1"
                        style={{
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          color: colors.darkBlue,
                        }}
                      >
                        {t("payTheBillComponent.verifiedPayment")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    textAlign: "center",

                    fontSize: "80%",
                    color: "grey",
                  }}
                >
                  <p></p>
                  <p>
                    {t("payTheBillComponent.generalTermsAndConditions1")}{" "}
                    <a
                      href="CGV_CoverCooker.pdf"
                      download="CGV_CoverCooker.pdf"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "grey",
                      }}
                    >
                      {t("payTheBillComponent.generalTermsAndConditions2")}
                    </a>{" "}
                    {t("payTheBillComponent.generalTermsAndConditions3")}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withTranslationHOC(PayTheBillComponent));
