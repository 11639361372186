import React, { Component } from "react";
import { colors } from "../colors";
import { Spring, animated } from "@react-spring/web";
import { fontSizes } from "../fontSizes";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, loading: false, big: false };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ big: !this.state.big });
      if (this.state.hover) {
        clearInterval(this.interval);
        this.setState({ big: false });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Spring
        transform={
          this.state.hover || this.state.big ? "scale(1.08)" : "scale(1)"
        }
        duration={500}
        boxShadow={
          this.state.hover
            ? `0 3px 8px ${colors.blue}`
            : `0 0px 0px ${colors.blue}`
        }
      >
        {(styles) => (
          <animated.div
            {...this.props}
            className="px-3"
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            style={{
              ...styles,
              cursor: "pointer",
              width: this.props.width ? this.props.width : "100%",
              height: 36,
              borderRadius: 5,
              background: `linear-gradient(to right, ${colors.red}, ${colors.darkBlue})`, // Updated line
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontFamily: "Manchego",
              fontSize: fontSizes.medium,
              color: colors.background,
            }}
          >
            {this.props.children}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default Button;
