import React, { Component } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { colors } from "../colors";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { Spring, animated, Transition } from "@react-spring/web";
import IngredientsComponent from "../components/IngredientsComponent";
import CookResumeComponent from "../components/CookResumeComponent";
import { withRouter } from "../withRouter";
import { withTranslationHOC } from "../withTranslation";
import { ThreeDots } from "react-loader-spinner";
import { me } from "../api";
import SetCookingSecret from "../components/SetCookingSecret";
import CookCoverComponent from "../components/CookCoverComponent";

import { fontSizes } from "../fontSizes";
import LoadingBar from "../components/LoadingBar";
import { ListCheck, Envelope, People } from "react-bootstrap-icons";

class CookingPage extends Component {
  state = {
    step: 1,
    comesFromCooker: false,
    resumeSelectedId: null,
  };

  changeStep = (step) => {
    this.setState({ step, comesFromCooker: false });
  };

  changeCoverLetterId = (coverLetterId) => {
    this.setState({ coverLetterId });
  };

  getUser = async () => {
    try {
      const user = await me();
      this.setState({ user });
    } catch (e) {
      console.log(e);
      this.props.navigate("/login");
    }
  };

  async resume() {
    const query = new URLSearchParams(this.props.location.search);
    const stepParam = query.get("step");
    const coverLetterIdParam = query.get("cover_letter_id");
    const resumeIdParam = query.get("resume_id");
    const interviewAdviceIdParam = query.get("interview_advice_id");
    const comesFromCooker = query.get("comes_from_cooker") === "true";
    if (stepParam) {
      this.setState({ step: 4 });
      this.checkPaymentInterval = setInterval(async () => {
        const user = await this.checkForPayment();
        if (user) {
          if (!user.password_set) {
            this.setState({ step: 5 });
          } else {
            this.setState({
              step: parseInt(stepParam, 10),
              resumeSelectedId: parseInt(resumeIdParam, 10),
              coverLetterId: parseInt(coverLetterIdParam, 10),
              interviewAdviceId: parseInt(interviewAdviceIdParam, 10),
              comesFromCooker: comesFromCooker,
            });
          }
          clearInterval(this.checkPaymentInterval);
          this.setState({ user });
        }
      }, 1000);
    } else {
      if (
        this.props.location.state?.from === "cooker" &&
        this.props.location.state?.coverLetterId &&
        this.props.location.state?.action === "edit"
      ) {
        this.setState({
          step: 3,
          resumeSelectedId: null,
          comesFromCooker: true,
          coverLetterId: this.props.location.state?.coverLetterId,
        });
      }
      if (
        this.props.location.state?.from === "cooker" &&
        this.props.location.state?.resumeId &&
        this.props.location.state?.action === "create"
      ) {
        console.log("resumeId", this.props.location.state?.resumeId);
        this.setState({
          step: 1,
          coverLetterId: null,
          comesFromCooker: true,
          resumeSelectedId: this.props.location.state?.resumeId,
        });
      }
      if (
        this.props.location.state?.from === "cooker" &&
        this.props.location.state?.resumeId &&
        this.props.location.state?.action === "analyse"
      ) {
        this.setState({
          step: 2,
          coverLetterId: null,
          comesFromCooker: true,
          resumeSelectedId: this.props.location.state?.resumeId,
        });
      }
    }
  }

  async componentDidMount() {
    await this.getUser();
    await this.resume();
  }

  continueAfterPasswordSet = () => {
    this.resume();
  };

  componentWillUnmount() {
    clearInterval(this.checkPaymentInterval);
  }

  checkForPayment = async () => {
    try {
      const user = await me();
      if (user && user.on_boarding_completed) {
        this.setState({ user });
        return user;
      }
      return false;
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { t } = this.props;

    if (this.state.user) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: colors.background,
          }}
        >
          <Navbar style={{ backgroundColor: colors.lightBlue }} expand="lg">
            <Container>
              <Navbar.Brand
                style={{ fontFamily: "Manchego", color: colors.darkBlue }}
                href="#home"
              >
                CoverCooker<span style={{ color: colors.background }}>.ai</span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {this.state.user?.on_boarding_completed && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="p-2 me-2"
                        style={{
                          display: "flex",
                          backgroundColor: colors.darkBlue,
                          borderRadius: 10,
                          fontFamily: "Manchego",
                          fontSize: fontSizes.normal,
                          color: colors.background,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.state.user?.credit_tips_resume}
                        <ListCheck
                          className="ms-1"
                          size={fontSizes.normal}
                        ></ListCheck>
                      </div>
                      <div
                        className="p-2 me-2"
                        style={{
                          display: "flex",

                          backgroundColor: colors.darkBlue,
                          borderRadius: 10,
                          fontFamily: "Manchego",
                          fontSize: fontSizes.normal,
                          color: colors.background,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.state.user?.credit_writing_letter}
                        <Envelope
                          className="ms-1"
                          size={fontSizes.normal}
                        ></Envelope>
                      </div>
                      <div
                        className="p-2 me-2"
                        style={{
                          display: "flex",

                          backgroundColor: colors.darkBlue,
                          borderRadius: 10,
                          fontFamily: "Manchego",
                          fontSize: fontSizes.normal,
                          color: colors.background,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.state.user?.credit_interview_advice}
                        <People
                          className="ms-1"
                          size={fontSizes.normal}
                        ></People>
                      </div>
                    </div>
                  )}

                  <Nav.Link
                    href="/cooker"
                    className="ms-auto"
                    style={{ textAlign: "left" }}
                  >
                    <Spring
                      boxShadow={
                        this.state.linkHovered
                          ? `0 0 10px ${colors.background}`
                          : `0 0 0px ${colors.background}`
                      }
                    >
                      {(styles) => (
                        <animated.div
                          className="px-2 py-1"
                          onMouseEnter={() => {
                            this.setState({ linkHovered: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ linkHovered: false });
                          }}
                          style={{
                            ...styles,
                            borderRadius: 10,
                            color: colors.darkBlue,
                            fontFamily: "Manchego",
                            fontSize: fontSizes.normal,
                            fontWeight: "bold",
                            backgroundColor: colors.background,
                          }}
                        >
                          {t("cookingPage.myLettersAndCVs")}
                        </animated.div>
                      )}
                    </Spring>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Container
            className="py-3"
            style={{
              marginTop: 0,
              paddingTop: 0,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <LoadingBar
              comesFromCooker={this.state.comesFromCooker}
              progress={(this.state.step * 100) / 3}
            />

            <Transition
              items={this.state.step}
              from={{ opacity: 0 }} // Start from fully transparent
              enter={{ opacity: 1 }} // Animate to fully opaque
              leave={{ opacity: 0 }} // Animate back to fully transparent when leaving
              exitBeforeEnter={true}
              onRest={() => {
                if (this.state.step !== 1) {
                  // Perform any additional actions after the animation completes and the component is "unmounted"
                }
              }}
            >
              {(styles, step) =>
                step && (
                  <animated.div style={{ ...styles, display: "flex", flex: 1 }}>
                    {(() => {
                      switch (step) {
                        case 1:
                          return (
                            <IngredientsComponent
                              changeCoverLetterId={this.changeCoverLetterId.bind(
                                this
                              )}
                              resumeSelectedId={this.state.resumeSelectedId}
                              loadLastCoverLetter={
                                this.state.loadLastCoverLetter
                              }
                              changeStep={this.changeStep.bind(this)}
                            />
                          );
                        case 2:
                          return (
                            <CookResumeComponent
                              getUser={this.getUser.bind(this)}
                              comesFromCooker={this.state.comesFromCooker}
                              changeStep={this.changeStep.bind(this)}
                              coverLetterId={this.state.coverLetterId}
                              resumeSelectedId={this.state.resumeSelectedId}
                            ></CookResumeComponent>
                          );
                        case 3:
                          return (
                            <CookCoverComponent
                              getUser={this.getUser.bind(this)}
                              comesFromCooker={this.state.comesFromCooker}
                              coverLetterId={this.state.coverLetterId}
                            ></CookCoverComponent>
                          );

                        case 4:
                          return (
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ThreeDots
                                visible={!this.state.analyseOk}
                                width={100}
                                color={colors.blue}
                                radius="9"
                                wrapperClass=""
                              />
                            </div>
                          );

                        case 5:
                          return (
                            <SetCookingSecret
                              continueAfterPasswordSet={this.continueAfterPasswordSet.bind()}
                              user={this.state.user}
                            ></SetCookingSecret>
                          );

                        default:
                          return null;
                      }
                    })()}
                  </animated.div>
                )
              }
            </Transition>
          </Container>
        </div>
      );
    }
  }
}

export default withRouter(withTranslationHOC(CookingPage));
