import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import { withRouter } from "./withRouter";
import { completeEmailVerification, loginUser } from "./api";
import LandingPage from "./screens/LandingPage";
import CookingPage from "./screens/CookingPage";
import CookerPage from "./screens/CookerPage";
import LoginPage from "./screens/LoginPage";
import LegalPage from "./screens/LegalPage";

class AuthenticatedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    const token = localStorage.getItem("token");

    if (!token) {
      setTimeout(() => this.props.navigate("/login"), 1000);
    }

    this.setState({ token: token });
  }

  render() {
    if (this.state.token) {
      return (
        <Routes>
          <Route path="/cooking" element={<CookingPage />} />
          <Route path="/cooker" element={<CookerPage />} />
        </Routes>
      );
    }
  }
}

const AuthenticatedRoutesWithRouter = withRouter(AuthenticatedRoutes);

class VerifyEmailRoutes extends Component {
  async componentDidMount() {
    try {
      await completeEmailVerification(
        this.props.params.email,
        this.props.params.verify_token
      );

      const loginUserResponse = await loginUser(
        this.props.params.email,
        "default_password"
      );
      localStorage.setItem("token", loginUserResponse.access_token);
      this.props.navigate("/cooking");
    } catch (error) {
      console.error("Error:", error);
      this.props.navigate("/");
    }
  }

  render() {
    return <div>Verifying email...</div>;
  }
}

const VerifyEmailRoutesWithRouter = withRouter(VerifyEmailRoutes);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading... </div>}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/legal" element={<LegalPage />} />

            <Route
              path="/verify/:email/:verify_token"
              element={<VerifyEmailRoutesWithRouter />}
            />

            <Route path="/*" element={<AuthenticatedRoutesWithRouter />} />
          </Routes>
        </Router>
      </Suspense>
    );
  }
}

export default App;
