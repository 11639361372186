import React from "react";
import { withTranslation } from "react-i18next";

export function withTranslationHOC(WrappedComponent) {
  function HOC(props) {
    return <WrappedComponent {...props} />;
  }

  return withTranslation()(HOC);
}
