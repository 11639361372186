import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { colors } from "../../colors";

class FooterCoverCooker extends Component {
  render() {
    return (
      <footer
        className="py-3 mt-5"
        style={{
          backgroundColor: colors.lightBlue,
          color: colors.darkBlue,
          fontFamily: "Montserrat",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={4}>
              <h5 style={{ fontFamily: "Manchego" }}>Help</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/legal" style={{ color: "inherit" }}>
                    Conditions générales <br />
                    d'utilisation et politique <br />
                    de confidentialité
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <h5 style={{ fontFamily: "Manchego" }}>Contact</h5>
              <p>hello@thecookingcompany.io</p>
            </Col>
            <Col md={4} className="text-center">
              <p style={{ fontFamily: "Manchego" }}>
                made with ❤️ in France by
              </p>
              <img
                src={require("../../assets/logo_cc.png")}
                alt=""
                style={{ width: "40%" }}
              ></img>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <p>CoverCooker © 2024</p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default FooterCoverCooker;
