import axios from "axios";
import { BASE_URL } from "./config";

const api = axios.create({
  baseURL: BASE_URL,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const verifyEmail = async (email) => {
  try {
    const response = await api.post(`/verify/?email_recipient=${email}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error during email verification:", error);
    throw error;
  }
};

export const completeEmailVerification = async (email, verificationToken) => {
  try {
    const response = await api.get(`/verify/${email}/${verificationToken}`);
    return response.data;
  } catch (error) {
    console.error("Error during email verification completion:", error);
    throw error;
  }
};

export const loginUser = async (username, password) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await api.post(`/token`, formData, config);
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const me = async () => {
  try {
    const response = await api.get(`/me`);
    return response.data;
  } catch (error) {
    console.error("Error during fetching user:", error);
    throw error;
  }
};

export const createUser = async () => {
  try {
    const response = await api.post(`/create`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error during user creation:", error);
    throw error;
  }
};

export const getResumesByUser = async () => {
  try {
    const response = await api.get(`/resumes`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error during fetching resumes:", error);
    throw error;
  }
};

export const uploadResume = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await api.post(`/resumes`, formData, config);
    return response.data;
  } catch (error) {
    console.error("Error during resume upload:", error);
    throw error;
  }
};
export const downloadResume = async (resumeId) => {
  try {
    const response = await api.get(`/resumes/download/${resumeId}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error during resume download:", error);
    throw error;
  }
};

export const getResumeById = async (resumeId) => {
  try {
    const response = await api.get(`/resumes/${resumeId}`);
    return response.data;
  } catch (error) {
    console.error("Error during fetching resume by ID:", error);
    throw error;
  }
};

export const uploadResumeForCoverLetter = async (file, coverLetterId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await api.post(
      `/cover_letters/${coverLetterId}/resumes`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error during resume upload for cover letter:", error);
    throw error;
  }
};

export const generateTips = async (resumeId) => {
  try {
    const response = await api.post(`/resumes/${resumeId}/generate_tips`);
    return response.data;
  } catch (error) {
    console.error("Error during generating tips for resume:", error);
    throw error;
  }
};

export const createCoverLetter = async (resumeId, coverLetterData) => {
  try {
    const response = await api.post(
      `/resumes/${resumeId}/cover_letters`,
      coverLetterData
    );
    return response.data;
  } catch (error) {
    console.error("Error during cover letter creation:", error);
    throw error;
  }
};

export const generateCoverLetter = async (coverLetterId) => {
  try {
    const response = await api.post(
      `/cover_letters/${coverLetterId}/generate_cover_letter`
    );
    return response.data;
  } catch (error) {
    console.error("Error during generating cover letter:", error);
    throw error;
  }
};

export const getCoverLettersByUser = async () => {
  try {
    const response = await api.get(`/cover_letters`);
    return response.data;
  } catch (error) {
    console.error("Error during fetching cover letters:", error);
    throw error;
  }
};

export const getCoverLetterById = async (coverLetterId) => {
  try {
    const response = await api.get(`/cover_letters/${coverLetterId}`);
    return response.data;
  } catch (error) {
    console.error("Error during fetching cover letter by ID:", error);
    throw error;
  }
};

export const getCoverLettersByResume = async (resumeId) => {
  try {
    const response = await api.get(`/resumes/${resumeId}/cover_letters`);
    return response.data;
  } catch (error) {
    console.error("Error during fetching cover letters by resume:", error);
    throw error;
  }
};

export const updateCoverLetter = async (coverLetterId, coverLetterData) => {
  try {
    const response = await api.put(
      `/cover_letters/${coverLetterId}`,
      coverLetterData
    );
    return response.data;
  } catch (error) {
    console.error("Error during updating cover letter:", error);
    throw error;
  }
};

export const getPackages = async () => {
  try {
    const response = await api.get("/packages/user/");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error during fetching packages:", error);
    throw error;
  }
};

export const createPaymentIntent = async (packageId) => {
  try {
    const response = await api.post(`/packages/${packageId}/pay`);
    return response.data;
  } catch (error) {
    console.error("Error during payment intent creation:", error);
    throw error;
  }
};

export const createCheckoutSession = async (metadata, packageId) => {
  try {
    const response = await api.post(
      `/packages/${packageId}/checkout-session`,
      metadata
    );
    return response.data;
  } catch (error) {
    console.error("Error during payment intent creation:", error);
    throw error;
  }
};

export const updatePassword = async (username, password) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await api.post(`/users/update_password`, formData, config);
    return response.data;
  } catch (error) {
    console.error("Error during password update:", error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error during user update:", error);
    throw error;
  }
};

export const updateResume = async (resumeId, resumeData) => {
  try {
    const response = await api.put(`/resumes/${resumeId}`, resumeData);
    return response.data;
  } catch (error) {
    console.error("Error during resume update:", error);
    throw error;
  }
};

export const deleteResume = async (resumeId) => {
  try {
    const response = await api.delete(`/resumes/${resumeId}`);
    return response.data;
  } catch (error) {
    console.error("Error during resume deletion:", error);
    throw error;
  }
};

export const redeemPromo = async (promoCode) => {
  try {
    const response = await api.post(`/promos/redeem/${promoCode}`);
    return response.data;
  } catch (error) {
    console.error("Error during promo redemption:", error);
    throw error;
  }
};

export const getInterviewAdviceById = async (interviewAdviceId) => {
  try {
    const response = await api.get(`/interview_advice/${interviewAdviceId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching interview advice:", error);
    throw error;
  }
};

export const getInterviewAdviceByUser = async () => {
  try {
    const response = await api.get(`/interview_advice`);
    return response.data;
  } catch (error) {
    console.error("Error fetching interview advice by user:", error);
    throw error;
  }
};

export const deleteInterviewAdvice = async (interviewAdviceId) => {
  try {
    const response = await api.delete(`/interview_advice/${interviewAdviceId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting interview advice:", error);
    throw error;
  }
};

export const updateInterviewAdvice = async (
  interviewAdviceId,
  interviewAdviceData
) => {
  try {
    const response = await api.put(
      `/interview_advice/${interviewAdviceId}`,
      interviewAdviceData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating interview advice:", error);
    throw error;
  }
};

export const createInterviewAdvice = async (
  coverLetterId,
  interviewAdviceData
) => {
  try {
    const response = await api.post(
      `/cover_letters/${coverLetterId}/interview_advice`,
      interviewAdviceData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating interview advice:", error);
    throw error;
  }
};

export const generateInterviewAdvice = async (interviewAdviceId) => {
  try {
    const response = await api.post(
      `/interview_advice/${interviewAdviceId}/generate_interview_advice`
    );
    return response.data;
  } catch (error) {
    console.error("Error generating interview advice:", error);
    throw error;
  }
};

export const getInterviewAdviceByCoverLetterId = async (coverLetterId) => {
  try {
    const response = await api.get(
      `/cover_letters/${coverLetterId}/interview_advice`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching interview advice for cover letter:", error);
    throw error;
  }
};
