import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { colors } from "../colors";
import Spinner from "react-bootstrap/Spinner";

class CustomButton extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, loading: false };
  }

  setHover = (hoverState) => {
    this.setState({ hover: hoverState });
  };

  async onClick() {
    this.setState({ loading: true });
    await this.props.onClick();
    this.setState({ loading: false });
  }

  render() {
    const defaultStyle = {
      backgroundColor: colors.red,
      borderColor: colors.red,
      fontFamily: "Manchego",
      width: "100%",
    };

    const hoverStyle = {
      backgroundColor: colors.red,
      borderColor: colors.red,
      boxShadow: "0 0 0 0.2rem " + colors.red + "40",
      fontFamily: "Manchego",
      width: "100%",
    };

    return (
      <Button
        // className="mt-2 mb-2"
        {...this.props}
        onClick={this.onClick.bind(this)}
        style={this.state.hover ? hoverStyle : defaultStyle}
        onMouseEnter={() => this.setHover(true)}
        onMouseLeave={() => this.setHover(false)}
        onFocus={() => this.setHover(true)}
        onBlur={() => this.setHover(false)}
      >
        {this.state.loading ? (
          <Spinner animation="border" role="status" size={"sm"}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          this.props.children
        )}
      </Button>
    );
  }
}

export default CustomButton;
