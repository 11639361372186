import React from "react";
import { Form } from "react-bootstrap";

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusStyle: {},
    };
  }

  handleFocus = () => {
    this.setState({
      focusStyle: {
        borderColor: this.props.animColor,
        boxShadow: "0 0 0 0.2rem " + this.props.animColor + "40",
      },
    });
  };

  handleBlur = (event) => {
    if (this.props.onBlur) this.props.onBlur(event);
    this.setState({
      focusStyle: {},
    });
  };

  render() {
    const { controlId } = this.props;
    const { focusStyle } = this.state;
    return (
      <Form>
        <Form.Group
          className={this.props.className}
          controlId={controlId}
          style={{ padding: 0, margin: 0 }}
        >
          <Form.Select
            value={this.props.value}
            onChange={this.props.onChange}
            style={{ ...this.props.style, ...focusStyle }}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          >
            {this.props.options.map((lang) => (
              <option key={lang.value} value={lang.value}>
                {lang.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
    );
  }
}

export default SelectInput;
