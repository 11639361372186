import React, { Component } from "react";
import { animated, Spring } from "@react-spring/web";
import { colors } from "../colors";
import { Col, Row } from "react-bootstrap";
import { fontSizes } from "../fontSizes";
import { ChevronLeft, FileEarmarkArrowDownFill } from "react-bootstrap-icons";
import { withRouter } from "../withRouter";
import { withTranslationHOC } from "../withTranslation";

class LoadingBar extends Component {
  render() {
    const { t } = this.props;

    let { progress } = this.props;
    progress = Math.min(100, Math.max(0, progress));
    return (
      <Row>
        <Col
          className="mb-3 mt-1"
          style={{
            fontFamily: "Manchego",
            alignItems: "center",
            backgroundColor: this.props.comesFromCooker
              ? colors.lightBlue
              : colors.background,

            borderRadius: 10,
            display: "flex",
            position: "relative",
            padding: 0,
          }}
        >
          {this.props.comesFromCooker ? (
            <div
              onClick={() => this.props.navigate("/cooker")}
              className="mb-2 mt-2 mx-3"
              style={{
                cursor: "pointer",
                color: colors.darkBlue,
                fontSize: fontSizes.medium,
                alignItems: "center",
                zIndex: 2,
              }}
            >
              <ChevronLeft
                className="me-2"
                size={fontSizes.medium}
              ></ChevronLeft>
              {t("loadingBar.back")}
            </div>
          ) : (
            <div
              className="mb-2 mt-2 mx-3"
              style={{
                color: colors.background,
                fontSize: fontSizes.large,
                display: "flex",
                alignItems: "center",
                zIndex: 2,
              }}
            >
              <FileEarmarkArrowDownFill
                className="me-2"
                size={fontSizes.large}
              ></FileEarmarkArrowDownFill>
              {t("loadingBar.generateLetter")}
            </div>
          )}
          {!this.props.comesFromCooker && (
            <Spring
              from={{ width: "0%" }}
              to={{ width: `${progress}%` }}
              config={{ duration: 250 }}
            >
              {(styles) => (
                <animated.div
                  style={{
                    ...styles,
                    position: "absolute",
                    zIndex: 1,
                    height: "100%",
                    background: `linear-gradient(to right, ${colors.red}, ${colors.darkBlue})`, // Updated line
                    borderRadius: 10,
                  }}
                />
              )}
            </Spring>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(withTranslationHOC(LoadingBar));
