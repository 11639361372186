// frontend/src/screens/LandingPage.js
import React, { Component } from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { colors } from "../colors";
import CustomButton from "../components/ButtonLanding";
import { ToastContainer, toast } from "react-toastify";
import { createUser, me } from "../api";
import { withRouter } from "../withRouter";
import { Spring, animated } from "@react-spring/web";
import { fontSizes } from "../fontSizes";
import Typewriter from "typewriter-effect";
import LogosCarousel from "../components/LogosCaroussel";
import FooterCoverCooker from "./utils/FooterCoverCooker";
import ReactGA from "react-ga4";
import { withTranslationHOC } from "../withTranslation";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkHovered: false,
    };
  }

  componentDidMount() {
    ReactGA.initialize("G-6DRE5W0TCL");
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get("source");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Landing Page",
    });

    if (source) {
      ReactGA.event({
        category: "Traffic",
        action: "Source",
        label: source,
      });
    }
  }

  async createLetter() {
    try {
      try {
        const user = await me();

        this.props.navigate("/cooking");
        return;
      } catch (error) {
        const token = await createUser();
        localStorage.setItem("token", token.access_token);
        this.props.navigate("/cooking");
        return;
      }
    } catch (error) {
      toast.error("Une erreur s'est produite", {
        style: {
          backgroundColor: colors.red,
          color: "white",
          fontFamily: "Montserrat",
        },
        progressStyle: {
          backgroundColor: colors.darkBlue,
        },
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div style={{ overflow: "visible" }}>
        <Navbar
          style={{ backgroundColor: colors.lightBlue, height: 64 }}
          expand="lg"
        >
          <Container>
            <Navbar.Brand
              style={{ fontFamily: "Manchego", color: colors.darkBlue }}
              href="/"
            >
              CoverCooker<span style={{ color: colors.background }}>.ai</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link
                  href="/login"
                  className="ms-auto"
                  style={{ textAlign: "left" }}
                >
                  <Spring
                    boxShadow={
                      this.state.linkHovered
                        ? `0 0 10px ${colors.background}`
                        : `0 0 0px ${colors.background}`
                    }
                  >
                    {(styles) => (
                      <animated.div
                        className="px-2 py-1"
                        onMouseEnter={() => {
                          this.setState({ linkHovered: true });
                        }}
                        onMouseLeave={() => {
                          this.setState({ linkHovered: false });
                        }}
                        style={{
                          ...styles,
                          borderRadius: 10,
                          color: colors.darkBlue,
                          fontFamily: "Manchego",
                          fontSize: fontSizes.normal,
                          fontWeight: "bold",
                          backgroundColor: colors.background,
                        }}
                      >
                        {t("landingPage.loginButton")}
                      </animated.div>
                    )}
                  </Spring>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: colors.lightBlue,
            overflow: "visible",
          }}
        >
          <Container
            className="d-flex justify-content-center align-items-center"
            style={{ overflow: "visible" }}
          >
            <Row
              className="d-flex align-items-end pt-5 pb-5"
              style={{
                display: "flex",
                width: "100%",
                position: "relative",
                overflow: "hidden",
                maxWidth: 1000,
              }}
            >
              <Col
                className="h-100"
                style={{
                  display: "flex",
                  width: "100%",
                  borderRadius: 10,
                  boxShadow: `0 0 10px ${colors.lightBlue}`,
                  zIndex: 1,
                }}
                md={12}
              >
                <h1
                  style={{
                    fontFamily: "Manchego",
                    color: colors.darkBlue,
                    minHeight: 108,

                    fontSize: fontSizes.xxlarge,
                  }}
                >
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("Writing cover letters ")
                        .typeString(
                          '<span style="color: ' +
                            colors.background +
                            ';">rocks</span>.'
                        )
                        .pauseFor(500)
                        .deleteChars(6) // Delete "sucks"
                        .typeString(
                          '<span style="color: ' +
                            colors.background +
                            ';">sucks</span>.'
                        ) // Replace with "rocks"
                        .pauseFor(500)
                        .typeString("<br/>Let the best ")
                        .typeString(
                          '<span style="color: ' +
                            colors.background +
                            ';">AI in town</span>'
                        )
                        .typeString(" cook it for you.")
                        .start();
                    }}
                    options={{
                      autoStart: true,
                      loop: false,
                      delay: 20,
                      cursor: "|",
                      wrapperClassName: "typewriter-wrapper",
                      cursorClassName: "typewriter-cursor",
                    }}
                  />
                </h1>
              </Col>
              <Col
                className="d-flex flex-column justify-content-between align-items-center h-100 mt-2"
                md={4}
                style={{ height: "100%", overflow: "visible" }}
              >
                <Col
                  md={12}
                  xs={10}
                  style={{
                    overflow: "visible",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    onClick={this.createLetter.bind(this)}
                    style={{
                      width: "100%",
                      backgroundColor: colors.red,
                      fontFamily: "Manchego",
                    }}
                  >
                    {t("landingPage.generateButton")}
                  </CustomButton>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          className="d-flex justify-content-center align-items-center flex-column mb-5"
          style={{ maxWidth: 1000 }}
        >
          {/* <div
          style={{
            height: 250,
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <img
            src="https://wallpapercave.com/wp/wp3269205.jpg"
            alt="CoverCooker Logo"
            style={{
              width: "100%",

              zIndex: 0,
            }}
          />
        </div> */}
          <ToastContainer hideProgressBar={true} />
          <Row className="justify-content-center align-items-center mt-5 mb-2">
            <Col
              md={10}
              className="p-2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                //backgroundColor: colors.lightBlue,
                background: `linear-gradient(to right, ${colors.red}, ${colors.blue})`,
              }}
            >
              <video
                src={require("../assets/landing_video.mp4")}
                autoPlay
                playsInline
                muted
                loop
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 6,
                  // boxShadow: `0 0 10px ${colors.lightBlue}`,
                }}
              />
            </Col>
          </Row>

          <Row
            className="d-flex justify-content-center align-items-center mt-5"
            style={{ maxWidth: 1000 }}
          >
            <Col
              className="d-flex justify-content-center align-items-center"
              md={12}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: colors.darkBlue,
                  fontFamily: "montserrat",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                {t("landingPage.tiredQuestion")}
              </h3>
            </Col>
          </Row>

          <Row
            className="d-flex justify-content-center align-items-center mt-2"
            style={{ maxWidth: 1000 }}
          >
            <Col
              className="d-flex justify-content-center align-items-center"
              md={12}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: colors.darkBlue,
                  fontFamily: "montserrat",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                {t("landingPage.lackInspirationQuestion")}
              </h3>
            </Col>
          </Row>
          <Row
            className="d-flex justify-content-center align-items-center mt-2"
            style={{ maxWidth: 1000 }}
          >
            <Col
              className="d-flex justify-content-center align-items-center"
              md={12}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: colors.darkBlue,
                  fontFamily: "Manchego",
                  fontWeight: "bold",
                  fontSize: "2em",
                }}
              >
                {t("landingPage.coverCookerSolution")}
              </h3>
            </Col>
          </Row>

          <div
            className="p-3 mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: colors.lightBlue,
              width: "100%",
              borderRadius: 10,
              padding: "20px",
              maxWidth: 1000,
            }}
          >
            <Row className="mb-4" style={{ maxWidth: 1000 }}>
              <Col md={12} className="text-right">
                <h2
                  style={{
                    color: colors.darkBlue,
                    fontFamily: "Manchego",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {t("landingPage.generateLetterHeading")}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} className="mb-3">
                <Spring
                  boxShadow={
                    this.state.link1Hovered
                      ? `0 0 10px ${colors.background}`
                      : `0 0 0px ${colors.background}`
                  }
                >
                  {(styles) => (
                    <animated.div
                      onMouseEnter={() => {
                        this.setState({ link1Hovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ link1Hovered: false });
                      }}
                      className="p-3"
                      style={{
                        ...styles,
                        backgroundColor: colors.background,
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.red,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                        }}
                      >
                        {t("landingPage.personalized")}
                      </h4>
                      <p
                        style={{
                          fontFamily: "montserrat",
                          color: colors.darkBlue,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t("landingPage.personalizedDescription")}
                      </p>
                    </animated.div>
                  )}
                </Spring>
              </Col>
              <Col md={6} xs={12} className="mb-3">
                <Spring
                  boxShadow={
                    this.state.link2Hovered
                      ? `0 0 10px ${colors.background}`
                      : `0 0 0px ${colors.background}`
                  }
                >
                  {(styles) => (
                    <animated.div
                      onMouseEnter={() => {
                        this.setState({ link2Hovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ link2Hovered: false });
                      }}
                      className="p-3"
                      style={{
                        ...styles,
                        backgroundColor: colors.background,
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.red,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                        }}
                      >
                        {t("landingPage.optimized")}
                      </h4>
                      <p
                        style={{
                          fontFamily: "montserrat",
                          color: colors.darkBlue,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t("landingPage.optimizedDescription")}
                      </p>
                    </animated.div>
                  )}
                </Spring>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} className="mb-3">
                <Spring
                  boxShadow={
                    this.state.link3Hovered
                      ? `0 0 10px ${colors.background}`
                      : `0 0 0px ${colors.background}`
                  }
                >
                  {(styles) => (
                    <animated.div
                      onMouseEnter={() => {
                        this.setState({ link3Hovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ link3Hovered: false });
                      }}
                      className="p-3"
                      style={{
                        ...styles,
                        backgroundColor: colors.background,
                        borderRadius: "5px",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.red,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                        }}
                      >
                        {t("landingPage.multiLanguage")}
                      </h4>
                      <p
                        style={{
                          fontFamily: "montserrat",
                          color: colors.darkBlue,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t("landingPage.multiLanguageDescription")}
                      </p>
                    </animated.div>
                  )}
                </Spring>
              </Col>
              <Col md={6} xs={12} className="mb-3">
                <Spring
                  boxShadow={
                    this.state.link4Hovered
                      ? `0 0 10px ${colors.background}`
                      : `0 0 0px ${colors.background}`
                  }
                >
                  {(styles) => (
                    <animated.div
                      onMouseEnter={() => {
                        this.setState({ link4Hovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ link4Hovered: false });
                      }}
                      className="p-3"
                      style={{
                        ...styles,
                        backgroundColor: colors.background,
                        borderRadius: "5px",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.red,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                        }}
                      >
                        {t("landingPage.fastAndSimple")}
                      </h4>
                      <p
                        style={{
                          fontFamily: "montserrat",
                          color: colors.darkBlue,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t("landingPage.fastAndSimpleDescription")}
                      </p>
                    </animated.div>
                  )}
                </Spring>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12} className="text-left">
                <h2
                  style={{
                    color: colors.darkBlue,
                    fontFamily: "Manchego",
                    fontWeight: "bold",
                  }}
                >
                  {t("landingPage.simpleSteps")}
                </h2>
              </Col>
            </Row>
            <Row className="mt-3" style={{ height: "100%" }}>
              <Col
                md={4}
                xs={12}
                className="mb-3"
                style={{ display: "flex", flex: 1 }}
              >
                <div
                  className="p-3"
                  style={{
                    backgroundColor: colors.lightBlue,
                    border: "2px solid " + colors.background,
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: colors.darkBlue,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.background,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        1
                      </h4>
                    </div>
                    <p
                      className="ms-2"
                      style={{
                        fontFamily: "Manchego",
                        color: colors.darkBlue,
                        fontSize: "1.5em",
                      }}
                    >
                      {t("landingPage.stepOne")}
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: "montserrat",
                      color: colors.darkBlue,
                    }}
                  >
                    {t("landingPage.stepOneDescription")}
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                xs={12}
                className="mb-3"
                style={{ display: "flex", flex: 1 }}
              >
                <div
                  className="p-3"
                  style={{
                    backgroundColor: colors.lightBlue,
                    border: "2px solid " + colors.background,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    borderRadius: "5px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: colors.darkBlue,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.background,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        2
                      </h4>
                    </div>
                    <p
                      className="ms-2"
                      style={{
                        fontFamily: "Manchego",
                        color: colors.darkBlue,
                        fontSize: "1.5em",
                      }}
                    >
                      {t("landingPage.stepTwo")}
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: "montserrat",
                      color: colors.darkBlue,
                    }}
                  >
                    {t("landingPage.stepTwoDescription")}
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                xs={12}
                className="mb-3"
                style={{ display: "flex", flex: 1 }}
              >
                <div
                  className="p-3"
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    backgroundColor: colors.lightBlue,
                    border: "2px solid " + colors.background,
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: colors.darkBlue,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          color: colors.background,
                          fontFamily: "Manchego",
                          fontWeight: "bold",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        3
                      </h4>
                    </div>
                    <p
                      className="ms-2"
                      style={{
                        fontFamily: "Manchego",
                        color: colors.darkBlue,
                        fontSize: "1.5em",
                      }}
                    >
                      {t("landingPage.stepThree")}
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: "montserrat",
                      color: colors.darkBlue,
                    }}
                  >
                    {t("landingPage.stepThreeDescription")}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <LogosCarousel />

        <Container
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ maxWidth: 1000 }}
        >
          <Row className="mt-4">
            <Col md={12} xs={12} style={{ height: "100%" }}>
              <CustomButton
                onClick={this.createLetter.bind(this)}
                width="100%"
                style={{
                  width: "100%",
                  backgroundColor: colors.red,
                  fontFamily: "Manchego",
                }}
              >
                {t("landingPage.generateButton")}
              </CustomButton>
            </Col>
          </Row>
        </Container>

        <FooterCoverCooker />
      </div>
    );
  }
}

export default withRouter(withTranslationHOC(LandingPage));
