import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusStyle: {},
    };
  }

  handleFocus = () => {
    this.setState({
      focusStyle: {
        borderColor: this.props.animColor,
        boxShadow: "0 0 0 0.2rem " + this.props.animColor + "40",
      },
    });
  };

  handleBlur = (event) => {
    if (this.props.onBlur) this.props.onBlur(event);
    this.setState({
      focusStyle: {},
    });
  };

  render() {
    const { controlId, type, placeholder } = this.props;
    const { focusStyle } = this.state;
    return (
      <Form.Group
        className={this.props.className}
        controlId={controlId}
        style={{ padding: 0, margin: 0 }}
      >
        <Form.Control
          disabled={this.props.disabled}
          as={this.props.as ? this.props.as : "input"}
          aria-placeholder="Enter your email"
          type={type}
          onChange={this.props.onChange}
          value={this.props.value}
          placeholder={placeholder}
          style={{ ...this.props.style, ...focusStyle }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </Form.Group>
    );
  }
}

TextInput.propTypes = {
  controlId: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

TextInput.defaultProps = {
  type: "text",
  placeholder: "",
  style: {},
};

export default TextInput;
